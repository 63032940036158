import React, {useEffect} from "react";
import {Button, Form, Input, notification, Select} from 'antd';
import {CheckCircleOutlined, ExclamationCircleOutlined, SaveOutlined} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

function LanguageChangeForm() {

    //Localisation
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const onFinish = () => {
        
    }

    return(
        <div id="LanguageChangeForm" style={{background: '#fff', borderRadius: '8px', padding: '5px', marginBottom: '10px'}}>
            <h2 style={{marginLeft: '10px'}}>{t('settings.general.language')}</h2>
                <Select 
                    style={{marginLeft: '10px', width: '120px'}}
                    defaultValue={i18n.language}
                    onChange={(value)=>changeLanguage(value)}
                    options={[
                        {
                            value: 'en',
                            label: 'English',
                        },
                        {
                            value: 'de',
                            label: 'German',
                        },
                    ]}
                />
               
        </div>    
    );

} export default LanguageChangeForm;