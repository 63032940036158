import React, {useEffect, useState} from "react";
import {Button, Modal, notification} from 'antd';
import DeviceAttributeTable from "./DeviceAttributeTable";
import DeviceAttributeAddForm from "./DeviceAttributeAddForm";
import {CheckCircleOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import {GetUrlPrefix} from "../../../config/HostConfig";

function DeviceAttributeOverview({device}) {

    //Localisation
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const [addFormIsVisible, setAddFormIsVisible] = useState(false)
    const [attributes, setAttributes] = useState([])

    const hideForm = () => {
        setAddFormIsVisible(false)
    }

    const showForm = () => {
        setAddFormIsVisible(true)
    }

    const onAttributeAdded = (attribute) => {
        hideForm()
        let tmpAttributes = {...attributes}
        tmpAttributes[attribute.identifier] = attribute
        setAttributes(tmpAttributes)
    }

    const deleteAttribute = (deviceId, attributeId, attributeIdentifier) => {
        const requestOptions = {
            method: 'DELETE',
        };
        fetch(GetUrlPrefix("device-controller") + `/devices/` + deviceId + "/attributes/" + attributeId, requestOptions)
            .then(response => {
                if (response.ok) {
                    onDelete(attributeIdentifier)
                    openSuccessNotification()
                } else {
                    response.text().then(message => {
                        openErrorNotification(message)
                    })
                }
            })
        return true
    }

    const openSuccessNotification = () => {
        notification.open({
            message: t('popups.attribute.deleteMsg.title'),
            description: t('popups.attribute.deleteMsg.message'),
            icon: (
                <CheckCircleOutlined style={{
                    color: 'green',
                }}
                />
            ),
        });
    };

    const openErrorNotification = (errorMessage) => {
        notification.open({
            message: t('popups.attribute.deleteMsgFail.title'),
            description: errorMessage,
            icon: (
                <ExclamationCircleOutlined style={{
                    color: 'red',
                }}
                />
            ),
        });
    };

    const onDelete = (identifier) => {
        let tmpAttributes = {...attributes}
        delete tmpAttributes[identifier]
        setAttributes(tmpAttributes)
    }

    const confirm = (deviceId, attributeId, attributeIdentifier, attributeName) => {
        Modal.confirm({
            title: t('devices.devicePage.attributes.deleteForm.title'),
            content:
                <>
                    <p>{t('devices.devicePage.attributes.deleteForm.message')}</p>
                    <p><b>{attributeName}</b></p>
                </>
            ,
            onOk: () => {
                deleteAttribute(deviceId, attributeId, attributeIdentifier)
            }
        });
    };

    async function getAttributes () {
        try {
            let response = await fetch(GetUrlPrefix("device-controller") + "/devices/" + device.id + "/attributes");
            const jsonData = await response.json();
            setAttributes(jsonData)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getAttributes()
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <div className="button-menu" style={{justifyContent: 'right'}}>
                <Button type="primary" onClick={() => {showForm()}}><PlusOutlined/>{t('buttons.add')}</Button>
            </div>
            <DeviceAttributeAddForm
                isVisible={addFormIsVisible}
                onCancel={hideForm}
                onAttributeAdded={onAttributeAdded}
                deviceId={device.id}
            />
            <DeviceAttributeTable attributes={attributes} device={device} onSelectDelete={confirm} />
        </div>
    );
}

export default DeviceAttributeOverview;