import './WaterCoolingApp.css';
import { Divider, Select, Layout, Button, Modal, Form, Space, Table, DatePicker, Collapse, Statistic, Row, Col, Card, Descriptions } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { GetUrlPrefix, GetWebsocketProtocol } from '../../../config/HostConfig';
import { testData } from './components/testData'

//Localisation
import { useTranslation } from 'react-i18next';

//Build panel constants
const { Header, Content, Footer } = Layout;
const { Option } = Select;
const { Panel } = Collapse;


  
      

function AppCoolingGuard() {

    //Localisation
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
  
    //Set initial state
    const [inverterId, setInverterId] = useState(1)
    const [form] = Form.useForm();
    const [allData, setAllData] = useState([]);
    const [choke1Data, setChoke1Data] = useState([]);
    const [choke2Data, setChoke2Data] = useState([]);
    const [choke3Data, setChoke3Data] = useState([]);
    const [choke4Data, setChoke4Data] = useState([]);
    const [registeredDevices, setRegisteredDevices] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

     
    const showModal = () => {
         setIsModalVisible(true);
     };
 
    const handleOk = () => {
         setIsModalVisible(false);
     };
 
    const handleCancel = () => {
         setIsModalVisible(false);
     };
    
    //Get registered devices
    async function getDevices () {
        try {
        let response = await fetch(GetUrlPrefix("device-controller") + `/applications/cooling-water-pro/devices?detailed=true`);
        const jsonData = await response.json();
        let deviceList = new Array()
        /* jsonData.forEach(function(device) {
            deviceList[device.id] = device
        })  */
        /* console.log(deviceList);
        console.log("registeredDevices", registeredDevices) */
        setRegisteredDevices(jsonData)
        } catch (error) {
        console.log(error);
        }
    }

    //Initial page load
    useEffect(() => {
        //let url = "ws://192.168.178.170:25308/ws-actual"
        let url = GetWebsocketProtocol() + ':25308/ws-actual'; 
        let c = new WebSocket(url);
        c.onopen = function() {
        };
        c.onmessage = function(msg){
        let data = JSON.parse(msg.data)
        if (data.length !== 0) {
            setAllData(data)
        }
        
        } 
        //getDevices();
    }, [])

    useEffect(() => {
        if (allData != undefined) {
            setChoke1Data(extractDeviceData(allData, 4))
            setChoke2Data(extractDeviceData(allData, 5))
            setChoke3Data(extractDeviceData(allData, 6))
            setChoke4Data(extractDeviceData(allData, 7))
        }

    }, [allData])

    const changeFurnace = (value)  => {
        setInverterId(value);
    }

    function extractDeviceData(jsonData, id) {
        let result = [];
        
        jsonData.forEach(device => {
          if (device.device_id === id) {
            device.lines.forEach(line => {
              result[line.id] = {
                "temp": line.temp,
                "temp_status": line.temp_status,
                "temp_trend_low": line.temp_trend_low,
                "temp_trend_high": line.temp_trend_high,
                "temp_ref": line.temp_ref,
                "flow": line.flow,
                "flow_status": line.flow_status,
                "flow_trend_low": line.flow_trend_low,
                "flow_trend_high": line.flow_trend_high,
                "flow_ref": line.flow_ref
              };
            });
          }
        });
        return result;
      }

    const getStatusColor = (status) => {
        let color;
        if (status >= 4) {
            color = "red";
        } else {
            switch (status) {
                case 0:
                    color = "white";
                    break;
                case 1:
                    color = "blue";
                    break;
                case 2:
                    color = "green";
                    break;
                case 3:
                    color = "yellow";
                    break;
                default:
                    color= "white"
                    break;
            }
        }
        return color
    }

    const getStatusText = (status, type) => {
        let text;
        if (status >= 4) {
            text = "Störung";
        } else {
        switch (status) {
            case 0:
                text = "";
                break;
            case 1:
                switch (type) {
                    case "temp":
                        text = "Frostgefahr";
                        break;
                    case "flow":
                        text = "Leitungsbruch";
                        break;
                    case "system":
                        text = "Frostgefahr/Leitungsbruch"
                    default:
                        text = "";
                        break;
                }       
                break;
            case 2:
                text = "OK";
                break;
            case 3:
                text = "Warnung";
                break;
            default:
                text = ""
                break;
        }
    }
        return text
    }

    const getStatusTextColor = (status) => {
        let color;
        if (status >= 4) {
            color = "white";
        } else {
            switch (status) {
                case 0:
                    color = "white";
                    break;
                case 1:
                    color = "white";
                    break;
                case 2:
                    color = "white";
                    break;
                case 3:
                    color = "black";
                    break;
                default:
                    color= "white"
                    break;
            }
        }
        return color
    }

    const getBoxShadow = (status) => {
        let shadow = ""
        if (status >= 4) {
            shadow = "0 0 15px 5px rgba(255, 51, 51, 0.5)"
        }
        return shadow
    }

    

    

    const linesSpaceInverter1Choke1 = () => { 
        const content = getLineStatusRenderings(4, 8);
        return content
    }

    const linesSpaceInverter1Choke2 = () => { 
        const content = getLineStatusRenderings(5, 8);
        return content
    }

    const linesSpaceInverter2Choke3 = () => { 
        const content = getLineStatusRenderings(6, 6);
        return content
    }
    const linesSpaceInverter2Choke4 = () => { 
        const content = getLineStatusRenderings(7, 6);
        return content
    }


    const getLineStatusRenderings = (choke_id, line_count) => {
        const chokeDataMapping = {
            4: choke1Data,
            5: choke2Data,
            6: choke3Data,
            7: choke4Data
        };
        const chokeData = chokeDataMapping[choke_id];
        if (!chokeData || chokeData.length < line_count) {
            return <div>Error: Invalid choke_id</div>;
        }
        
        return (
            <Space direction="vertical" style={{marginBottom: "10px"}}>
                <Space style={{marginTop: 12}}>
                    <Card
                        title="Strang 1"
                        bodyStyle={{ padding: 0 }}
                    >
                        <div style={{ display: 'flex', height: "100%" }}>
                            <div style={{
                                boxShadow: getBoxShadow(chokeData[1].temp_status), 
                                backgroundColor: getStatusColor(chokeData[1].temp_status), 
                                color: getStatusTextColor(chokeData[1].temp_status), 
                                flex: 1,
                                borderBottomLeftRadius: '8px',
                                textAlign: 'center'
                            }}>
                                <h4>°C</h4>
                            </div>
                            <div style={{
                                boxShadow: getBoxShadow(chokeData[1].flow_status), 
                                backgroundColor: getStatusColor(chokeData[1].flow_status), 
                                color: getStatusTextColor(chokeData[1].flow_status), 
                                flex: 1,
                                borderBottomRightRadius: '8px',
                                textAlign: 'center'
                            }}>
                                <h4>l/min</h4>
                            </div>
                        </div>
                    </Card>
                    <Card
                        title="Strang 2"
                        bodyStyle={{ padding: 0 }}
                    >
                        <div style={{ display: 'flex', height: "100%" }}>
                            <div style={{
                                boxShadow: getBoxShadow(chokeData[2].temp_status), 
                                backgroundColor: getStatusColor(chokeData[2].temp_status), 
                                color: getStatusTextColor(chokeData[2].temp_status), 
                                flex: 1,
                                borderBottomLeftRadius: '8px',
                                textAlign: 'center'
                            }}>
                                <h4>°C</h4>
                            </div>
                            <div style={{
                                boxShadow: getBoxShadow(chokeData[2].flow_status), 
                                backgroundColor: getStatusColor(chokeData[2].flow_status), 
                                color: getStatusTextColor(chokeData[2].flow_status), 
                                flex: 1,
                                borderBottomRightRadius: '8px',
                                textAlign: 'center'
                            }}>
                                <h4>l/min</h4>
                            </div>
                        </div>
                    </Card>
                    <Card
                        title="Strang 3"
                        bodyStyle={{ padding: 0 }}
                    >
                        <div style={{ display: 'flex', height: "100%" }}>
                            <div style={{
                                boxShadow: getBoxShadow(chokeData[3].temp_status), 
                                backgroundColor: getStatusColor(chokeData[3].temp_status), 
                                color: getStatusTextColor(chokeData[3].temp_status), 
                                flex: 1,
                                borderBottomLeftRadius: '8px',
                                textAlign: 'center'
                            }}>
                                <h4>°C</h4>
                            </div>
                            <div style={{
                                boxShadow: getBoxShadow(chokeData[3].flow_status), 
                                backgroundColor: getStatusColor(chokeData[3].flow_status), 
                                color: getStatusTextColor(chokeData[3].flow_status), 
                                flex: 1,
                                borderBottomRightRadius: '8px',
                                textAlign: 'center'
                            }}>
                                <h4>l/min</h4>
                            </div>
                        </div>
                    </Card>
                    {line_count === 8 && (
                        <Card
                            title="Strang 4"
                            bodyStyle={{ padding: 0 }}
                        >
                            <div style={{ display: 'flex', height: "100%" }}>
                                <div style={{
                                    boxShadow: getBoxShadow(chokeData[4].temp_status), 
                                    backgroundColor: getStatusColor(chokeData[4].temp_status), 
                                    color: getStatusTextColor(chokeData[4].temp_status), 
                                    flex: 1,
                                    borderBottomLeftRadius: '8px',
                                    textAlign: 'center'
                                }}>
                                    <h4>°C</h4>
                                </div>
                                <div style={{
                                    boxShadow: getBoxShadow(chokeData[4].flow_status), 
                                    backgroundColor: getStatusColor(chokeData[4].flow_status), 
                                    color: getStatusTextColor(chokeData[4].flow_status), 
                                    flex: 1,
                                    borderBottomRightRadius: '8px',
                                    textAlign: 'center'
                                }}>
                                    <h4>l/min</h4>
                                </div>
                            </div>
                    </Card>
                    )}
                </Space>
                <Space>
                    <Card
                        title={line_count === 8 ? "Strang 5" : "Strang 4"}
                        bodyStyle={{ padding: 0 }}
                    >
                        <div style={{ display: 'flex', height: "100%" }}>
                            <div style={{
                                boxShadow: line_count === 8 ? getBoxShadow(chokeData[5].temp_status) :  getBoxShadow(chokeData[4].temp_status),
                                backgroundColor: line_count === 8 ? getStatusColor(chokeData[5].temp_status) : getStatusColor(chokeData[4].temp_status), 
                                color: line_count === 8 ? getStatusTextColor(chokeData[5].temp_status) : getStatusTextColor(chokeData[4].temp_status), 
                                flex: 1,
                                borderBottomLeftRadius: '8px',
                                textAlign: 'center'
                            }}>
                                <h4>°C</h4>
                            </div>
                            <div style={{ 
                                boxShadow: line_count === 8 ? getBoxShadow(chokeData[5].flow_status) :  getBoxShadow(chokeData[4].flow_status),
                                backgroundColor: line_count === 8 ? getStatusColor(chokeData[5].flow_status) : getStatusColor(chokeData[4].flow_status), 
                                color: line_count === 8 ? getStatusTextColor(chokeData[5].flow_status) : getStatusTextColor(chokeData[4].flow_status), 
                                flex: 1,
                                borderBottomRightRadius: '8px',
                                textAlign: 'center'
                            }}>
                                <h4>l/min</h4>
                            </div>
                        </div>
                    </Card>
                    <Card
                        title={line_count === 8 ? "Strang 6" : "Strang 5"}
                        bodyStyle={{ padding: 0 }}
                    >
                        <div style={{ display: 'flex', height: "100%" }}>
                            <div style={{
                                boxShadow: line_count === 8 ? getBoxShadow(chokeData[6].temp_status) :  getBoxShadow(chokeData[5].temp_status), 
                                backgroundColor: line_count === 8 ? getStatusColor(chokeData[6].temp_status) : getStatusColor(chokeData[5].temp_status), 
                                color: line_count === 8 ? getStatusTextColor(chokeData[6].temp_status) : getStatusTextColor(chokeData[5].temp_status), 
                                flex: 1,
                                borderBottomLeftRadius: '8px',
                                textAlign: 'center'
                            }}>
                                <h4>°C</h4>
                            </div>
                            <div style={{
                                boxShadow: line_count === 8 ? getBoxShadow(chokeData[6].flow_status) :  getBoxShadow(chokeData[5].flow_status), 
                                backgroundColor: line_count === 8 ? getStatusColor(chokeData[6].flow_status) : getStatusColor(chokeData[5].flow_status), 
                                color: line_count === 8 ? getStatusTextColor(chokeData[6].flow_status) : getStatusTextColor(chokeData[5].flow_status), 
                                flex: 1,
                                borderBottomRightRadius: '8px',
                                textAlign: 'center'
                            }}>
                                <h4>l/min</h4>
                            </div>
                        </div>
                    </Card>
                    <Card
                        title={line_count === 8 ? "Strang 7" : "Strang 6"}
                        bodyStyle={{ padding: 0 }}
                    >
                        <div style={{ display: 'flex', height: "100%" }}>
                            <div style={{
                                boxShadow: line_count === 8 ? getBoxShadow(chokeData[7].temp_status) :  getBoxShadow(chokeData[6].temp_status), 
                                backgroundColor: line_count === 8 ? getStatusColor(chokeData[7].temp_status) : getStatusColor(chokeData[6].temp_status), 
                                color: line_count === 8 ? getStatusTextColor(chokeData[7].temp_status) : getStatusTextColor(chokeData[6].temp_status), 
                                flex: 1,
                                borderBottomLeftRadius: '8px',
                                textAlign: 'center'
                            }}>
                                <h4>°C</h4>
                            </div>
                            <div style={{
                                boxShadow: line_count === 8 ? getBoxShadow(chokeData[7].flow_status) :  getBoxShadow(chokeData[6].flow_status), 
                                backgroundColor: line_count === 8 ? getStatusColor(chokeData[7].flow_status) : getStatusColor(chokeData[6].flow_status), 
                                color: line_count === 8 ? getStatusTextColor(chokeData[7].flow_status) : getStatusTextColor(chokeData[6].flow_status), 
                                flex: 1,
                                borderBottomRightRadius: '8px',
                                textAlign: 'center'
                            }}>
                                <h4>l/min</h4>
                            </div>
                        </div>
                    </Card>
                    {line_count === 8 && (
                        <Card
                            title="Strang 8"
                            bodyStyle={{ padding: 0}}
                        >
                            <div style={{ display: 'flex', height: "100%" }}>
                                <div style={{
                                    boxShadow: getBoxShadow(chokeData[8].temp_status),  
                                    backgroundColor: getStatusColor(chokeData[8].temp_status), 
                                    color: getStatusTextColor(chokeData[8].temp_status), 
                                    flex: 1,
                                    borderBottomLeftRadius: '8px',
                                    textAlign: 'center'
                                }}>
                                    <h4>°C</h4>
                                </div>
                                <div style={{
                                    boxShadow: getBoxShadow(chokeData[8].flow_status),  
                                    backgroundColor: getStatusColor(chokeData[8].flow_status), 
                                    color: getStatusTextColor(chokeData[8].flow_status), 
                                    flex: 1,
                                    borderBottomRightRadius: '8px',
                                    textAlign: 'center'
                                }}>
                                    <h4>l/min</h4>
                                </div>
                            </div>
                        </Card>
                    )}
                </Space>
            </Space>
        )
    }


    const getLineRenderings = (choke_id, line_id) => {
        const chokeDataMapping = {
            4: choke1Data,
            5: choke2Data,
            6: choke3Data,
            7: choke4Data
        };
        const chokeData = chokeDataMapping[choke_id];
        if (!chokeData) {
            return <div>Error: Invalid choke_id</div>;
        }
        if (!chokeData[line_id]) {
            return <div>Error: Invalid line_id</div>;
        }

        return (
            <Panel header={`Strang ${line_id}`} key={line_id}>
                <h3 style={{margin: 0}}>Temperatur</h3>
                <Space>
                    <Card
                        title="Status"
                        style={{ minWidth: 120}}
                        bodyStyle={{ 
                            boxShadow: getBoxShadow(chokeData[line_id].temp_status), 
                            backgroundColor: getStatusColor(chokeData[line_id].temp_status),
                            color: getStatusTextColor(chokeData[line_id].temp_status),
                            textAlign: "center" }}>
                        {getStatusText(chokeData[line_id].temp_status, "temp")}
                    </Card>
                    <Card
                        title="Aktuell">
                            {chokeData[line_id].temp} °C
                    </Card>
                    <Card
                        title="Referenz">
                            {chokeData[line_id].temp_ref} °C
                    </Card>
                    <Card
                        title="Trend niedrig">
                            {chokeData[line_id].temp_trend_low} °C
                    </Card>
                    <Card
                        title="Trend hoch">
                            {chokeData[line_id].temp_trend_high} °C
                    </Card>
                </Space>
                <h3 style={{marginTop: 16, marginBottom: 0}}>Durchflussmenge</h3>
                <Space>
                    <Card
                        title="Status"
                        style={{ minWidth: 120}}
                        bodyStyle={{
                            boxShadow: getBoxShadow(chokeData[line_id].flow_status),  
                            backgroundColor: getStatusColor(chokeData[line_id].flow_status),
                            color: getStatusTextColor(chokeData[line_id].flow_status),
                            textAlign: "center" }}>
                        {getStatusText(chokeData[line_id].flow_status, "flow")}
                    </Card>
                    <Card
                        title="Aktuell">
                            {chokeData[line_id].flow} l/min
                    </Card>
                    <Card
                        title="Referenz">
                            {chokeData[line_id].flow_ref} l/min
                    </Card>
                    <Card
                        title="Trend niedrig">
                            {chokeData[line_id].flow_trend_low} l/min
                    </Card>
                    <Card
                        title="Trend hoch">
                            {chokeData[line_id].flow_trend_high} l/min
                    </Card>
                </Space>
            </Panel>
        )
    }

    const lineCollapseInverter1Choke1 = () => {
        const content = 
            <Collapse defaultActiveKey={[]} style={{marginTop: 16, marginBottom: 80}} >
                {getLineRenderings(4,1)}
                {getLineRenderings(4,2)}
                {getLineRenderings(4,3)}
                {getLineRenderings(4,4)}
                {getLineRenderings(4,5)}
                {getLineRenderings(4,6)}
                {getLineRenderings(4,7)}
                {getLineRenderings(4,8)}  
            </Collapse>
        return content
    }

    const lineCollapseInverter1Choke2 = () => { 
        const content =
            <Collapse defaultActiveKey={[]} style={{marginTop: 16, marginBottom: 80}} >
                {getLineRenderings(5,1)}
                {getLineRenderings(5,2)}
                {getLineRenderings(5,3)}
                {getLineRenderings(5,4)}
                {getLineRenderings(5,5)}
                {getLineRenderings(5,6)}
                {getLineRenderings(5,7)}
                {getLineRenderings(5,8)}
            </Collapse>
        return content
    }

    const lineCollapseInverter2Choke3 = () => {
        const content = 
            <Collapse defaultActiveKey={[]} style={{marginTop: 16, marginBottom: 80}} >
                {getLineRenderings(6,1)}
                {getLineRenderings(6,2)}
                {getLineRenderings(6,3)}
                {getLineRenderings(6,4)}
                {getLineRenderings(6,5)}
                {getLineRenderings(6,6)}
            </Collapse>
        return content
    }

    const lineCollapseInverter2Choke4 = () => {
        const content = 
            <Collapse defaultActiveKey={[]} style={{marginTop: 16, marginBottom: 80}} >
                {getLineRenderings(7,1)}
                {getLineRenderings(7,2)}
                {getLineRenderings(7,3)}
                {getLineRenderings(7,4)}
                {getLineRenderings(7,5)}
                {getLineRenderings(7,6)} 
            </Collapse>
        return content
    }

    function getHighestStatusForDevice(device) {
        let highestStatus = -1; 
    
        device.lines.forEach(line => {
            if (line.temp_status > highestStatus) {
                highestStatus = line.temp_status;
            }
            if (line.flow_status > highestStatus) {
                highestStatus = line.flow_status;
            }
        });
    
        return highestStatus;
    }

    function getHighestStatusFor2Devices(data, id_1, id_2) {
        let result = -1;
    
        data.forEach(device => {
            if (device.device_id === id_1 || device.device_id === id_2) {
                const highestStatusForDevice = getHighestStatusForDevice(device);
                if (highestStatusForDevice > result) {
                    result = highestStatusForDevice;
                }
            }
        });
        return result;
    }


    return (
        <div className="app">
        <Layout >
        {/* <img src="/img/app-water-cooling.png" style={{width:"100px"}} /> */}
        
        
        <Content style={{ padding: '15px 50px'}}>
            <h1 style={{marginBottom: "10px"}}>{t('applications.water-cooling-redux.headers.system-overview')} <InfoCircleOutlined onClick={showModal} /></h1>
            <Space>
                <Card
                    title={(
                        <>
                            System 1 
                            <br/>
                            <span style={{fontSize: '12px', fontWeight: 'normal'}}>
                                (Ofen 1 + 2)
                            </span>
                        </>
                    )}
                    style={{ minWidth: 120}}
                    bodyStyle={{
                        boxShadow: getBoxShadow(getHighestStatusFor2Devices(allData, 4, 5)), 
                        backgroundColor: getStatusColor(getHighestStatusFor2Devices(allData, 4, 5)), 
                        color: getStatusTextColor(getHighestStatusFor2Devices(allData, 4, 5)),
                        textAlign: "center" 
                    }}
                >
                    {getStatusText(getHighestStatusFor2Devices(allData, 4, 5), "system")}
                </Card>
                <Card
                    title={(
                        <>
                            System 6 
                            <br/>
                            <span style={{fontSize: '12px', fontWeight: 'normal'}}>
                                (Ofen 6)
                            </span>
                        </>
                    )}style={{ minWidth: 120}}
                    bodyStyle={{
                        boxShadow: getBoxShadow(getHighestStatusFor2Devices(allData, 6, 7)),
                        backgroundColor: getStatusColor(getHighestStatusFor2Devices(allData, 6, 7)), 
                        color: getStatusTextColor(getHighestStatusFor2Devices(allData, 6, 7)),
                        textAlign: "center" 
                    }}
                >
                    {getStatusText(getHighestStatusFor2Devices(allData, 6, 7), "system")}
                </Card>
            </Space>
            <Divider />
            <Space>
                <h1 style={{marginBottom: "10px"}}>{t('applications.water-cooling-redux.headers.line-overview')}</h1>
                <Form 
                form={form} 
                name="InverterDropdown"
                initialValues={{
                inverter_select: 1,
                }}>
                    <Form.Item name="inverter_select" style={{marginBottom: "10px"}}>
                        <Select style={{ width: 176, paddingLeft: 16 }}
                            onChange={changeFurnace}
                        >
                            <Option key={1} value={1}>System 1</Option>
                            <Option key={2} value={2}>System 6</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Space>
            <Divider />
            <br/>

        <Row gutter={16}>
            <Col span={12}>
                <h1 style={{marginBottom: "10px"}}>{inverterId === 1 ? "Drossel 1" : "Drossel 1"}</h1>
                {inverterId === 1 && (
                    <>
                        {linesSpaceInverter1Choke1()}
                        {lineCollapseInverter1Choke1()}
                    </>
                )}
                {inverterId === 2 && (
                    <>
                        {linesSpaceInverter2Choke3()}
                        {lineCollapseInverter2Choke3()}
                    </>
                )}
            </Col>
            <Col span={12}>
            <h1 style={{marginBottom: "10px"}}>{inverterId === 1 ? "Drossel 2" : "Drossel 2"}</h1>
            {inverterId === 1 && (
                    <>
                        {linesSpaceInverter1Choke2()}
                        {lineCollapseInverter1Choke2()}
                    </>
                )}
                {inverterId === 2 && (
                    <>
                        {linesSpaceInverter2Choke4()}
                        {lineCollapseInverter2Choke4()}
                    </>
                )}
            </Col>
        </Row>
        <Modal 
            title="Legende" 
            visible={isModalVisible} 
            onOk={handleOk} 
            onCancel={handleCancel}
            footer={[
                <Button key="ok" type="primary" onClick={handleOk}>
                    OK
                </Button>,
            ]}
        >
            <Descriptions bordered column={1}>
                <Descriptions.Item className="area-blue" label={
                    <div style={{ backgroundColor: 'blue', color: 'white', height: '100%', textAlign: 'center', borderTopLeftRadius: '8px'}}>
                        Blau
                    </div>
                }>
                    Temperaturmessung: Frostgefahr <br/>Durchflussmessung: Leitungsbruch
                </Descriptions.Item>
                <Descriptions.Item className="area-green" label={
                    <div style={{ backgroundColor: 'green', color: 'white', height: '100%', textAlign: 'center'}}>
                        Grün
                    </div>
                }>
                    OK
                </Descriptions.Item>
                <Descriptions.Item className="area-yellow" label={
                    <div style={{ backgroundColor: 'yellow', height: '100%', textAlign: 'center'}}>
                        Gelb
                    </div>
                }>
                    Warnung
                </Descriptions.Item>
                <Descriptions.Item className="area-red" label={
                    <div style={{ backgroundColor: 'red', color: 'white', height: '100%', textAlign: 'center', borderBottomLeftRadius: '8px' }}>
                        Rot
                    </div>
                }>
                    Störung
                </Descriptions.Item>
            </Descriptions>
        </Modal>
        </Content>
        </Layout>
    </div>
    );
}
export default AppCoolingGuard;

