import React from "react";
import { Button, Tabs } from 'antd';
import {CheckCircleOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons";
import LanguageChangeForm from "../components/settings/LanguageChangeForm";
import AppInstallationsTable from "../components/settings/AppInstallationsTable";


function SettingsPage() {

    return (
        <div>
        <h1>Settings</h1>
            <Tabs
                type="card"
                items={[
                    {
                        label: `General`,
                        key: '1',
                        children:
                            <LanguageChangeForm />
                    },
                    {
                        label: `Global Connectors`,
                        key: '2',
                        children:
                            <div>
                                <p>Text</p>
                            </div>
                    },
                    {
                        label: `Shifts`,
                        key: '3',
                        children:
                            <div>
                                <p>Text</p>
                            </div>
                    },
                    {
                        label: `Operators`,
                        key: '4',
                        children:
                            <div>
                                <p>Text</p>
                            </div>
                    },
                    {
                        label: `App Installations`,
                        key: '5',
                        children:
                            <AppInstallationsTable />
                    },

                    ]}
            />
        </div>
    )
} export default SettingsPage;
