import React, {useEffect, useState} from "react";
import { Button, Space, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

function DeviceTable({deviceList, onSelectDelete}) {

    //Localisation
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const [formattedDevices, setFormattedDevices] = useState([])

    const mapDevices = (rawData) => {
        let devices = []

        for(let i = 0; i < rawData.length; i++) {
            devices.push({
                id: rawData[i].id,
                key: rawData[i].id,
                name: rawData[i].name,
                description: rawData[i].description,
                mqtt_name: rawData[i].mqtt_name,
                system_mqtt_name: rawData[i].system_mqtt_name,
            })
        }

        setFormattedDevices(devices)
    }

    const columns = [
        {
            title: t('devices.table.name'),
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <a href={"/devices/" + record.id}>{text}</a>,
        },
        {
            title: t('devices.table.description'),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: t('devices.table.mqttName'),
            dataIndex: 'mqtt_name',
            key: 'mqtt_name',
        },
        {
            title: t('devices.table.systemMqttName'),
            dataIndex: 'system_mqtt_name',
            key: 'system_mqtt_name',
        },
        {
            title: t('devices.table.action'),
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button icon={<DeleteOutlined />} onClick={() => {onSelectDelete(record.id, record.name)}}>{t('buttons.delete')}</Button>
                    {t('buttons.clone')}
                </Space>
            ),
        },
    ];

    useEffect(() => {
        mapDevices(deviceList)
        // eslint-disable-next-line
    }, [deviceList])

    return (
        <Table columns={columns} dataSource={formattedDevices} />
    );
}

export default DeviceTable;