import React from 'react';
import { createRoot } from 'react-dom/client'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import DevicePage from "./pages/Devices/DevicePage";
import AppPage from "./pages/AppPage";
import DeviceDetails, {loader as deviceLoader} from "./pages/Devices/DeviceDetails";
import AttributeEditForm, {loader as attributeLoader} from "./pages/Devices/AttributeEditForm";
import SettingsPage from './pages/SettingsPage';
import ConditionMonitoringApp from './pages/Applications/ConditionMonitoring/ConditionMonitoringApp'
import WaterCoolingAppBasic from './pages/Applications/WaterCooling/WaterCoolingAppBasic';
import WaterCoolingAppPro from './pages/Applications/WaterCooling/WaterCoolingAppPro';
import AppCoolingGuard from './pages/Applications/WaterCooling/AppCoolingGuard';
import AppOptiChargeGuide from './pages/Applications/OptiCharge/AppOptiChargeGuide';
import AppInductorGuard from './pages/Applications/InduTrack/AppInductorGuard';
import AppSmartMeltGuide from './pages/Applications/SmartMelt/AppSmartMeltGuide';
//import KpiApp from "./pages/Applications/Kpi/KpiApp";

const root = createRoot(document.getElementById('root'));

const router = createBrowserRouter([
    {
        path: "/",
        element: <App content={<AppPage/>} menu={"applications"} />,
    },
    {
        path: "applications",
        element: <App content={<AppPage/>} menu={"applications"} />,
    },
    {
        path: "applications/condition-monitoring",
        element: <App content={<ConditionMonitoringApp/>} menu={"condition-monitoring"} selectedApp={"Condition Monitoring"} logo={"app-condition-monitoring.png"} />,
    },
    {
        path: "applications/watercooling-basic",
        element: <App content={<WaterCoolingAppBasic/>} menu={"watercooling-basic"} selectedApp={"Watercooling App Basic"} logo={"app-water-cooling.png"}/>,
    },
    {
        path: "applications/watercooling-pro",
        element: <App content={<WaterCoolingAppPro/>} menu={"watercooling-pro"} selectedApp={"Watercooling App Pro"} logo={"app-water-cooling.png"}/>,
    },
    {
        path: "applications/cooling-guard",
        element: <App content={<AppCoolingGuard/>} menu={"watercooling-redux"} selectedApp={"Cooling Guard"} logo={"app-cooling-guard.png"}/>,
    },
    /* {
        path: "applications/kpi",
        element: <App content={<KpiApp/>} menu={"kpi"} selectedApp={"KPI"} logo={"app-kpi.png"}/>,
    }, */
    {
        path: "applications/opticharge-guide",
        element: <App content={<AppOptiChargeGuide/>} menu={"opticharge-guide"} selectedApp={"OptiCharge Guide"} logo={"app-opticharge-guide.png"}/>,
    },
    {
        path: "applications/inductor-guard",
        element: <App content={<AppInductorGuard/>} menu={"inductor-guard"} selectedApp={"Inductor Guard"} logo={"app-inductor-guard.png"}/>,
    },
    {
        path: "applications/smartmelt-guide",
        element: <App content={<AppSmartMeltGuide/>} menu={"smartmelt-guide"} selectedApp={"SmartMelt Guide"} logo={"app-smartmelt-guide.png"}/>,
    },
    {
        path: "devices/",
        element: <App content={<DevicePage/>} menu={"devices"} />,
    },
    {
        path: "devices/:deviceId",
        element: <App content={<DeviceDetails/>} menu={"devices"} />,
        loader: deviceLoader,
    },
    {
        path: "devices/:deviceId/attributes/:attributeId",
        element: <App content={<AttributeEditForm/>} menu={"devices"} />,
        loader: attributeLoader,
    },
    {
        path: "settings",
        element: <App content={<SettingsPage/>} menu={"settings"} />,
    },
    
]);

root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
