import React, { useState, useEffect } from 'react';
import { Statistic } from 'antd';
import { useTranslation } from 'react-i18next';


function Statistics({shownRows}) {

    const statisticStyle = {
        marginRight: "12px",
        marginTop: "8px",
        marginBottom: "16px",
        padding: "4px",
        //border: "1px solid #bfbfbf",
        //minWidth: "200px",
        //backgroundColor: "#fafafa",
    }

    const [energy, setEnergy] = useState(0)
    const [energyProcess, setEnergyProcess] = useState(0)
    const [events, setEvents] = useState(0)

    const { t } = useTranslation();

    const calculateEnergy = (rows) => {
        let energy = 0
        let energyProcess = 0

        for (let i = 0; i < rows.length; i++) {
            energy += rows[i].losses

            /* if (rows[i].selectedReason === 4 || rows[i].selectedReason === 5) {
                energyProcess += rows[i].calculatedLosses
            } */
        }

        setEnergy(energy)
        setEnergyProcess(energyProcess)
    }

    useEffect(() => {
        calculateEnergy(shownRows)
        setEvents(shownRows.length)
    }, [shownRows])

    return (
        
            <div style={{display: "flex"}}>
                <div style={statisticStyle}>
                    <Statistic title={t('applications.smartmelt.statistics.energy')} value={energy} />
                </div>
               {/*  <div style={statisticStyle}>
                    <Statistic title={t('applications.smartmelt.statistics.energyProcess')} value={energyProcess} />
                </div> */}
                <div style={statisticStyle}>
                    <Statistic title={t('applications.smartmelt.statistics.events')} value={events} />
                </div>
            </div>
        
    )
}
export default Statistics;