import React, { useState, useEffect } from 'react';
import './InduTrackApp.css';
import Plot from 'react-plotly.js';
import { Select, Layout, Form, Button, Space, Input, Row, Col, Tooltip, Divider } from 'antd';
import { PlusOutlined, WarningOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import AddInductorModal from './components/AddInductorModal';
import {GetUrlPrefix} from "../../../config/HostConfig";

dayjs.extend(customParseFormat);

const { TextArea } = Input;

// Build panel constants
const { Header, Content, Footer } = Layout;
const { Option, OptGroup } = Select;

function AppInductorGuard() {
  const [registeredDevices, setRegisteredDevices] = useState([]);
  const [allJourneys, setAllJourneys] = useState([]);
  const [deviceJourneys, setDeviceJourneys] = useState({ active: [], archive: [] });
  const [currentJourney, setCurrentJourney] = useState({});
  const [currentDeviceJourneyOptions, setCurrentDeviceJourneyOptions] = useState([]);
  const [startedJourneySelected, setStartedJourneySelected] = useState(false);
  const [plotData, setPlotData] = useState({});
  const [plotDataLine, setPlotDataLine] = useState({});
  const [furnaceId, setFurnaceId] = useState(null);
  const [currentFurnaceName, setCurrentFurnaceName] = useState('-');
  const [addInductorModalIsOpen, setAddInductorModalIsOpen] = useState(0);
  const [form] = Form.useForm();

  const layout = {
    xaxis: {
      title: 'R [Ω]',
      titlefont: { size: 16 }
    },
    yaxis: {
      title: 'X [Ω]',
      titlefont: { size: 16 }
    },
    showlegend: true,
    paper_bgcolor: '#F5F5F5',
    plot_bgcolor: '#F5F5F5',
    width: 800,
    height: 450,
    margin: {t: 40, r: 0, pad:0},
    legend: {x: 0, y: 1.1},
  };

  const layout2 = {
    xaxis: {
      title: null,
      titlefont: { size: 16 }
    },
    yaxis: {
      title: 'R [Ω]',
      titlefont: { size: 16 },
      side: 'left'
    },
    yaxis2: {
      title: 'X [Ω]',
      titlefont: { size: 16 },
      side: 'right',
      overlaying: 'y'
    },
    showlegend: true,
    paper_bgcolor: '#F5F5F5',
    plot_bgcolor: '#F5F5F5',
    width: 1400,
    height: 280,
    margin: {t: 20, r: 0, pad:0},
    legend: {x: 0, y: 1.25},
  };

  // Get registered devices
  async function getDevices() {
    try {
      let response = await fetch(GetUrlPrefix("device-controller") + `/applications/inductor-management/devices?detailed=true`);
      const jsonData = await response.json();
      setRegisteredDevices(jsonData);
      if (jsonData.length > 0) {
        const minFurnaceId = Math.min(...jsonData.map(device => device.id));
        setFurnaceId(minFurnaceId);
        form.setFieldsValue({ FurnaceSelection: minFurnaceId });
        changeFurnace(minFurnaceId);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Get all journeys
  async function getAllJourneys() {
    try {
      let response = await fetch(GetUrlPrefix("app-inductor-management") + `/applications/inductor-management/journeys`);
      const jsonData = await response.json();
      setAllJourneys(jsonData);
      if (jsonData.active) {
        if (!jsonData.active.length === 0) { 
          setCurrentJourney(jsonData.active[0]);
          form.setFieldsValue({ InductorSelection: jsonData.active[0].journey_id });
          changeInductor(jsonData.active[0].journey_id);
        } 
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Get device journeys
  async function getDeviceJourneys(deviceId) {
    try {
      let response = await fetch(GetUrlPrefix("app-inductor-management") + `/applications/inductor-management/journeys/devices/` + deviceId);
      const jsonData = await response.json();
      setDeviceJourneys(jsonData);
      const activeJourneys = [
        ...(jsonData.active || []),
        ...(jsonData.started || [])
      ];
      if (activeJourneys) {
        if (!activeJourneys.length === 0) {
          setCurrentJourney(activeJourneys[0]);
          form.setFieldsValue({ InductorSelection: activeJourneys[0].journey_id });
          changeInductor(activeJourneys[0].journey_id);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Get current journey
  async function getCurrentJourney(journeyId) {
    try {
      let response = await fetch(GetUrlPrefix("app-inductor-management") + `/applications/inductor-management/journeys/` + journeyId);
      const jsonData = await response.json();
      setCurrentJourney(jsonData);
      const deviceId = jsonData?.information?.device_id;
      if (deviceId) {
        setCurrentFurnaceName(getDeviceNameById(deviceId));
      }
    } catch (error) {
      console.log(error);
    }
  }

  const changeFurnace = (value) => {
    form.setFieldsValue({ InductorSelection: null });
    setPlotData({ Grenze: {}, Messwerte: {} });
    setPlotDataLine({});
    setFurnaceId(value);
    if (value === 'all') {
      getAllJourneys();
    } else {
      getDeviceJourneys(value);
    }
  };

  const changeInductor = (value) => {
    getCurrentJourney(value);
  };

  const openInductorModal = () => {
    setAddInductorModalIsOpen(addInductorModalIsOpen + 1);
  };

  const closeInductorModal = () => {
    setAddInductorModalIsOpen(false);
  };

  const buildCurrentDeviceJourneyOptions = (journeyData) => {
    if (!journeyData) {
      return [];
    }

    const activeJourneys = [
      ...(journeyData.active || []),
      ...(journeyData.started || [])
    ];

    const options = [
      activeJourneys.length > 0 && {
        label: 'Aktiv',
        options: activeJourneys.map(journey => ({
          value: journey.journey_id,
          label: (
            <Tooltip title=
              {
                <>
                  <div>Gehäuse: {journey.case_name}</div>
                  <div>Spule: {journey.coil_name}</div>
                </>
              }
            >
              <>
                <b>{journey.journey_id}</b>
                <span> | </span>
                <b>{journey.journey_name}</b> 
                <span> | </span>
                {
                  furnaceId === 'all' &&
                    <>
                      <i>Ofen: {getDeviceNameById(journey.device_id)}</i>
                      <span> | </span>
                    </>
                  }
                <i>{formatDateTitle(journey.journey_date_start)}</i>
                <span> - </span>
                <i>{formatDateTitle(journey.journey_date_end)}</i>
              </>
            </Tooltip>
          )
        }))
      },
      journeyData.archive && {
        label: 'Archiviert',
        options: journeyData.archive.map(journey => ({
          value: journey.journey_id,
          label: (
            <Tooltip title=
              {
                <>
                  <div>Gehäuse: {journey.case_name}</div>
                  <div>Spule: {journey.coil_name}</div>
                </>
              }
            >
              <>
                <b>{journey.journey_id}</b>
                <span> | </span>
                <b>{journey.journey_name}</b> 
                <span> | </span>
                {
                  furnaceId === 'all' &&
                    <>
                      <i>Ofen: {getDeviceNameById(journey.device_id)}</i>
                      <span> | </span>
                    </>
                  }
                <i>{formatDateTitle(journey.journey_date_start)}</i>
                <span> - </span>
                <i>{formatDateTitle(journey.journey_date_end)}</i>
              </>
            </Tooltip>
          )
        }))
      },
    ].filter(group => group && group.options.length > 0);
    return options;
  };

  const buildPlotDataLine = (input) => {
    if (!input || !input.square || !input.dots) {
      return {
        xData: [],
        yDataR: [],
        yDataX: []
      };
    }
    const xData = input.dots.map(item => dayjs(item.date).format('YYYY-MM-DD HH:mm:ss'));
    const yDataR = input.dots.map(item => item.x);
    const yDataX = input.dots.map(item => item.y);
    const chartDataLine = {
      xData: xData,
      yDataR: yDataR,
      yDataX: yDataX
    }
    return chartDataLine 
  }

  const buildPlotData = (input) => {
    if (!input || !input.square || !input.dots) {
      return { Grenze: {}, Messwerte: {} };
    }
    const squareCoords = input.square.map(item => ({ x: item.x, y: item.y }));
    squareCoords.push(squareCoords[0]); // Close the rectangle by adding the first point to the end
    const dotCoords = input.dots.map(item => ({ x: item.x, y: item.y }));
    const dotDates = input.dots.map(item => dayjs(item.date).format('DD.MM.YYYY HH:mm'));
    const pointColors = dotCoords.map((_, index) => {
      const ratio = index / (dotCoords.length - 1);
      const red = Math.round(255 * (1 - ratio));
      const blue = Math.round(255 * ratio);
      return `rgba(${red}, 0, ${blue}, 1)`;
    });
    const chartData = {
      Grenze: {
        x: squareCoords.map(coord => coord.x),
        y: squareCoords.map(coord => coord.y),
        mode: 'lines',
        name: 'Grenze',
        line: { color: 'black', width: 2 }
      },
      Messwerte: {
        x: dotCoords.map(coord => coord.x),
        y: dotCoords.map(coord => coord.y),
        mode: 'lines+markers',
        name: 'Messwerte',
        marker: { color: pointColors, size: 8 },
        text: dotDates,
        hovertemplate: 'Datum: %{text} Uhr<br>R[Ω]: %{x}<br>X[Ω]: %{y}<extra></extra>'
      }
    }
    return chartData;
  };

  useEffect(() => {
    getDevices();
  }, []);

  useEffect(() => {
    setCurrentDeviceJourneyOptions(buildCurrentDeviceJourneyOptions(deviceJourneys));
  }, [deviceJourneys]);

  useEffect(() => {
    if (furnaceId !== null && furnaceId !== 'all') {
      getDeviceJourneys(furnaceId);
    } else if (furnaceId === 'all') {
      setDeviceJourneys(allJourneys);
    }
  }, [furnaceId]);

  useEffect(() => {
    setPlotData(buildPlotData(currentJourney));
    setPlotDataLine(buildPlotDataLine(currentJourney))
  }, [currentJourney]);

  

  const formatDate = (date) => date ? dayjs(date).format('DD.MM.YYYY HH:mm') : '-';
  const formatDateTitle = (date) => date ? dayjs(date).format('DD.MM.YYYY') : '-';
  const getDisplayValue = (value) => value && value.trim() !== '' ? value : '-';
  const getDisplayValueInt = (value) => value && value !== null ? value : '-';
  const getDeviceNameById = (id) => {
    const device = registeredDevices.find(device => device.id === id);
    return device ? device.name : '-';
  };

  return (
    <div className="app" style={{ marginLeft: 9, marginRight: 9 }}>
      <Layout>
        <Content>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "0px"}}>
          <Space>
            <Form form={form} name="Form1" labelCol={{ span: 12 }} wrapperCol={{ span: 12 }} style={{ marginTop: '16px', minWidth: '40vw' }}>
              <Row gutter={16} >
                  <Form.Item name="FurnaceSelection" label={<h3 style={{ color: "#F58155" }}>Ofenauswahl</h3>} style={{ marginBottom: "0px" }}>
                    <Select style={{ width: 180, marginBottom: 16 }} showArrow onChange={changeFurnace} value={furnaceId}>
                      <Option key="all" value="all">Alle Öfen</Option>
                      {
                        registeredDevices.map((device) => {
                          return (<Option key={device.id} value={device.id}>{device.name}</Option>);
                        })
                      }
                    </Select>
                  </Form.Item>
                  <Form.Item name="InductorSelection" label={<h3 style={{ color: "#F58155" }}>Induktorauswahl</h3>} style={{ marginBottom: "0px" }}>
                    <Select 
                      style={{ width: furnaceId === 'all' ? 360 : 280, marginBottom: 16 }}
                      options={currentDeviceJourneyOptions} 
                      showArrow 
                      onChange={changeInductor}>
                      {currentDeviceJourneyOptions.map(group => (
                        <OptGroup key={group.label} label={group.label}>
                          {group.options.map(option => (
                            <Option key={option.value} value={option.value}>
                              {option.label}
                            </Option>
                          ))}
                        </OptGroup>
                      ))}
                    </Select>
                  </Form.Item>
              </Row>
            </Form>
          </Space>
            {startedJourneySelected && <Space style={{marginLeft: 140}}><WarningOutlined style={{ fontSize: "36px", color: "#F58155" }} /></Space>}
          </div>
          <br />
          <br />
          <Row gutter={24}>
            <Col span={13}>
              <div style={{ minWidth: '30vw' }}>
                <Plot
                  data={[plotData.Grenze, plotData.Messwerte]}
                  layout={layout}
                  config={{
                    displaylogo: false,
                    displayModeBar: true,
                    showTips: false,
                    //staticPlot: true,
                    toImageButtonOptions: {
                      format: 'png', 
                      filename: 'custom_image',
                      height: 650,
                      width: 1000,
                      scale: 1 
                    },
                    modeBarButtonsToAdd: ['toImage'],
                    modeBarButtonsToRemove: ['zoom2d', 'pan2d', 'select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d']
                  }}
                  style={{height: '100%'}}
                />
              </div>
              {/* <Divider style={{ marginTop: "2px", marginBottom: "8px"}} /> */}
              <div>
                <Plot
                  data={[
                    {
                      x: plotDataLine.xData,
                      y: plotDataLine.yDataR,
                      mode: 'lines',
                      name: 'R [Ω]',
                      yaxis: 'y1',
                      line: { color: 'red' }
                    },
                    {
                      x: plotDataLine.xData,
                      y: plotDataLine.yDataX,
                      mode: 'lines',
                      name: 'X [Ω]',
                      yaxis: 'y2',
                      line: { color: 'blue' }
                    }
                  ]}
                  layout={layout2}
                  config={{
                    displaylogo: false,
                    displayModeBar: true,
                    showTips: false,
                    toImageButtonOptions: {
                      format: 'png', 
                      filename: 'custom_image',
                      height: 650,
                      width: 1000,
                      scale: 1 
                    },
                    modeBarButtonsToAdd: ['toImage'],
                    modeBarButtonsToRemove: ['zoom2d', 'pan2d', 'select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d']
                  }}
                  style={{height: '100%'}}
                />
              </div>
            </Col>
            <Col span={11} style={{ maxHeight: '500px'}}>
              <h3 style={{ color: "#F58155", marginTop: '00px' }}>Informationen:</h3>
              <Row>
                <Col span={7}>
                  <div>Reisenname:</div>
                  <div>Reise-ID:</div>
                  <div>Ofen:</div>
                  <div>Gehäusename:</div>
                  <div>Spulenname:</div>
                  <div>Startdatum:</div>
                  <div>Enddatum:</div>
                  <div>Kommentar:</div>
                </Col>
                <Col span={17}>
                  <div> {getDisplayValue(currentJourney?.information?.journey_name)}</div>
                  <div> {getDisplayValueInt(currentJourney?.information?.journey_id)}</div>
                  <div> {currentFurnaceName}</div>
                  <div> {getDisplayValue(currentJourney?.information?.case_name)}</div>
                  <div> {getDisplayValue(currentJourney?.information?.coil_name)}</div>
                  <div> {formatDate(currentJourney?.information?.journey_date_start)} Uhr</div>
                  <div> {formatDate(currentJourney?.information?.journey_date_end)} Uhr</div>
                  <div> {getDisplayValue(currentJourney?.information?.journey_comment)}</div>
                </Col>
              </Row>
              <br/>
              <h3 style={{ color: "#F58155", marginTop: '0px' }}>Situation:</h3>
              <Row>
                <Col>
                <div>{currentJourney?.evaluation?.description ?? '-'}</div>
                </Col>
              </Row>
              <br/>
              <h3 style={{ color: "#F58155", marginTop: '0px' }}>Hinweis:</h3>
              <Row>
                <Col>
                <div>{currentJourney?.evaluation?.instruction ?? '-'}</div>
                </Col>
              </Row>
              <br />
              <Button 
                type="primary" 
                disabled={furnaceId === 'all'} 
                onClick={() => openInductorModal()}
              >
                <PlusOutlined /> Neuer Induktor</Button>
            </Col>
          </Row>
          
        </Content>
        <Footer>&copy; {new Date().getFullYear()} ABP Induction</Footer>
      </Layout>
      <AddInductorModal isOpen={addInductorModalIsOpen} deviceId={furnaceId} />
    </div>
  );
}

export default AppInductorGuard;