import { Form, Input, InputNumber, Modal, Popconfirm, Button } from 'antd';
import { useEffect, useState } from "react";
import {GetUrlPrefix} from "../../../../config/HostConfig";

function AddInductorModal({ isOpen, deviceId }) {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const { TextArea } = Input;

    const layout = {
        labelCol: {
            span: 9,
        },
        wrapperCol: {
            span: 13,
        },
    };

    useEffect(() => {
        if (isOpen) {
            form.setFieldsValue({ device_id: deviceId });
        }
    }, [isOpen, deviceId, form]);

    useEffect(() => {
        setIsModalOpen(isOpen);
    }, [isOpen]);

    const handleOk = () => {
        form.validateFields().then(() => {
            const formValues = form.getFieldsValue();
            const InductorInformation = createInductorInformation(formValues);
            handleInductorCreation(InductorInformation);
            setIsModalOpen(false);
            form.resetFields();
        });
    };

    const createInductorInformation = (input) => {
        const item = {
            "device_id": input.device_id,
            "journey_name": input.journey_name,
            "journey_comment": input.journey_comment,
            "case_name": input.case_name,
            "coil_name": input.coil_name,
            "monitoring_interval_in_hours": input.monitoring_interval_in_hours
        };
        return item;
    };

    async function handleInductorCreation(information) {
        try {
            const result = await createInductor(information);
            if (result === 200) {
                setIsModalOpen(false);
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function createInductor(information) {
        return fetch(GetUrlPrefix("app-inductor-management") + `/applications/inductor-management/devices/${deviceId}/journeys`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify(information)
        })
            .then(response => response.status);
    }

    const onFinish = (values) => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Modal
                title="Neue Induktorreise hinzufügen"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Abbrechen
                    </Button>,
                    <Popconfirm
                        title={<><p>Sind Sie sicher, dass Sie eine neue Induktorreise anlegen möchten?</p><p>Die aktive Induktorreise wird beendet und kann <b>nicht</b> erneut gestartet werden.</p></>}
                        onConfirm={handleOk}
                        okText="Ja"
                        cancelText="Nein"
                    >
                        <Button key="submit" type="primary">
                            OK
                        </Button>
                    </Popconfirm>
                ]}
            >
                <Form
                    {...layout}
                    form={form}
                    onFinish={onFinish}
                    initialValues={{ device_id: deviceId }}
                >
                    <Form.Item
                        name="device_id"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Reisenname"
                        name="journey_name"
                        rules={[{ required: true, message: 'Bitte Reisennamen eingeben!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Kommentar"
                        name="journey_comment"
                    >
                        <TextArea rows={3} maxLength={3} />
                    </Form.Item>
                    <Form.Item
                        label="Gehäusename"
                        name="case_name"
                        rules={[{ required: true, message: 'Bitte Gehäusenamen eingeben!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Spulenname"
                        name="coil_name"
                        rules={[{ required: true, message: 'Bitte Spulennamen eingeben!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Monitoring Intervall [h]"
                        name="monitoring_interval_in_hours"
                        rules={[{ required: true, message: 'Bitte Monitoring Intervall eingeben!' }]}
                    >
                        <InputNumber precision={0} min={1} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}
export default AddInductorModal;
