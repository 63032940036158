import React, {useEffect, useState} from "react";
import { Button, Form, Input, notification, Select } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined, LeftOutlined, SaveOutlined } from '@ant-design/icons';
import { useLoaderData } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {GetUrlPrefix} from "../../config/HostConfig";

export async function loader({ params }) {
    return {
        attributeId: params.attributeId,
        deviceId: params.deviceId,
    }
}

function AttributeEditForm() {

    //Localisation
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const [attribute, setAttribute] = useState({
        id: "",
        identifier: "",
        name: "",
        description: "",
        deviceId: "",
        dataType: "",
        value: "",
    })
    
    const [device, setDevice] = useState({name: ""})
    const [form] = Form.useForm()
    const params = useLoaderData();

    async function getAttribute () {
        try {
            let response = await fetch(GetUrlPrefix("device-controller") + `/devices/` + params.deviceId + "/attributes/id/" + params.attributeId);
            const jsonData = await response.json();
            let attr = mapAttribute(jsonData)
            setAttribute(attr)
        } catch (error) {
            console.log(error);
        }
    }

    const mapAttribute = (rawData) => {
        return {
            id: rawData.id,
            identifier: rawData.identifier,
            name: rawData.name,
            description: rawData.description,
            deviceId: rawData.deviceId,
            dataType: rawData.dataType,
            value: rawData.value,
        }
    }

    async function getDevice () {
        try {
            let response = await fetch(GetUrlPrefix("device-controller") + `/devices/` + params.deviceId);
            const jsonData = await response.json();
            setDevice(mapDevice(jsonData))
        } catch (error) {
            console.log(error);
        }
    }

    const mapDevice = (rawData) => {
        return {
            name: rawData.name,
        }
    }

    const onFinish = (values) => {
        const requestOptions = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                identifier: values.identifier,
                name: values.name,
                description: values.description,
                dataType: values.dataType,
                value: values.value,
            })
        };
        fetch(GetUrlPrefix("device-controller") + `/devices/` + params.deviceId + "/attributes/" + params.attributeId, requestOptions)
            .then(response => {
                if (response.ok) {
                    onUpdate(values)
                    openSuccessNotification()
                } else {
                    response.text().then(message => {
                        openErrorNotification(message)
                    })
                }
            })
    };

    const onUpdate = (values) => {
        setAttribute({
            ...attribute,
            identifier: values.identifier,
            name: values.name,
            description: values.description,
            dataType: values.dataType,
            value: values.value,
        });
    }

    const openSuccessNotification = () => {
        notification.open({
            message: t('popups.attribute.updateMsg.title'),
            description: t('popups.attribute.updateMsg.message'),
            icon: (
                <CheckCircleOutlined style={{
                    color: 'green',
                }}
                />
            ),
        });
    };

    const openErrorNotification = (errorMessage) => {
        notification.open({
            message: t('popups.attribute.updateMsgFail.title'),
            description: errorMessage,
            icon: (
                <ExclamationCircleOutlined style={{
                    color: 'red',
                }}
                />
            ),
        });
    };

    // "Back" button for attributes list of device
    const openDevicePage = () => {
        window.open("/devices/" + params.deviceId, "_self");
    }

    useEffect(() => {
        getDevice()
        getAttribute()
        // eslint-disable-next-line
    }, [params])

    useEffect(() => {
        if (typeof params !== 'undefined' && params.length !== 0) {
            form.resetFields();
        }
        // eslint-disable-next-line
    }, [attribute])

    return (
        <div>
            <h1>{device.name}: {attribute.name}</h1>
            <Button type="default" onClick={() => {openDevicePage()}}><LeftOutlined />{t('devices.devicePage.attributes.addUpdateForm.navBack')}</Button>
            <Form
                autoComplete="off"
                form={form}
                initialValues={{
                    id: attribute.id,
                    identifier: attribute.identifier,
                    name: attribute.name,
                    description: attribute.description,
                    deviceId: attribute.deviceId,
                    dataType: attribute.dataType,
                    value: attribute.value,
                }}
                labelCol={{ span: 4 }}
                name="basic"
                onFinish={onFinish}
                wrapperCol={{ span: 14 }}
            >
                <Form.Item
                    hidden={true}
                    label="Id"
                    name="id"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    hidden={true}
                    label="Device Id"
                    name="deviceId"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('devices.devicePage.attributes.addUpdateForm.id')}
                    name="identifier"
                    rules={[{ required: true, message: t('devices.devicePage.attributes.addUpdateForm.errMsgId') }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('devices.devicePage.attributes.addUpdateForm.name')}
                    name="name"
                    rules={[{ required: true, message: t('devices.devicePage.attributes.addUpdateForm.erMsgName') }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('devices.devicePage.attributes.addUpdateForm.description')}
                    name="description"
                    rules={[{ required: true, message: t('devices.devicePage.attributes.addUpdateForm.errMsgDesc') }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('devices.devicePage.attributes.addUpdateForm.dataType')}
                    name="dataType"
                    rules={[{ required: true, message: t('devices.devicePage.attributes.addUpdateForm.errMsgType') }]}
                >
                    <Select
                        options={[
                            {
                                value: 'boolean',
                                label: 'Boolean',
                            },
                            {
                                value: 'float',
                                label: 'Float',
                            },
                            {
                                value: 'number',
                                label: 'Number',
                            },
                            {
                                value: 'string',
                                label: 'String',
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    label={t('devices.devicePage.attributes.addUpdateForm.value')}
                    name="value"
                    rules={[{ required: true, message: t('devices.devicePage.attributes.addUpdateForm.errMsgValue') }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        <SaveOutlined />{t('buttons.save')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default AttributeEditForm;