//import "antd/dist/antd.css";
import { Space, Table} from "antd";
import React, { useState, useEffect } from 'react';
import SmallLight from "./SmallLight";
import { useTranslation } from 'react-i18next';


const PreReqTable1 = ({indicatorData, furnaceId}) => {

   //----------------------------Localisation----------------------
   const { t, i18n } = useTranslation();
   const changeLanguage = (lng) => {
     i18n.changeLanguage(lng);
   };

    const columns = [
        {
          title: t('applications.opticharge.precondition.title'),
          dataIndex: 'preReqTitle',
          key: 'preReqTitle',
          //width: 30,
        },
        {
          title: t('applications.opticharge.precondition.status'),
          key: 'status',
          dataIndex: 'signalLight',
          //width: 500px,
          align: 'center',
          render: (_, record) => (
            <Space size="middle">
              <a><SmallLight id={1} input={record.signalLight}/></a> 
              <a><SmallLight id={2} input={record.signalLight}/></a>
            </Space>
          ),
        },
      ];

      let tableData = []
      if (furnaceId > 0) {
          tableData = [
            {
              key: '1',
              preReqTitle: t('applications.opticharge.precondition.1'),
              signalLight: indicatorData[furnaceId].MeltModeNotActive,
            },
            {
              key: '2',
              preReqTitle: t('applications.opticharge.precondition.2'),
              signalLight: indicatorData[furnaceId].PowerSetTooLow,
            },
            {
              key: '3',
              preReqTitle: t('applications.opticharge.precondition.3'),
              signalLight: indicatorData[furnaceId].WeightTooHigh,
            },
            {
              key: '4',
              preReqTitle: t('applications.opticharge.precondition.4'),
              signalLight: indicatorData[furnaceId].TemperatureTooLow,
            },
          ];
      } else {
        tableData = [
          {
            key: '1',
            preReqTitle: t('applications.opticharge.precondition.1'),
            signalLight: 0,
          },
          {
            key: '2',
            preReqTitle: t('applications.opticharge.precondition.2'),
            signalLight: 0,
          },
          {
            key: '3',
            preReqTitle: t('applications.opticharge.precondition.3'),
            signalLight: 0,
          },
          {
            key: '4',
            preReqTitle: t('applications.opticharge.precondition.4'),
            signalLight: 0,
          },
        ];
      }
      
      



    return (
        <Table 
            pagination={false} 
            columns={columns}
            dataSource={tableData}
            //size="small"
        />
      );
    }
    export default PreReqTable1