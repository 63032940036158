import { Form, Input, InputNumber, Modal, Popconfirm, Button, Tabs, Table, Select, Row, Col } from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined
  } from '@ant-design/icons';
import { useEffect, useState } from "react";

//Localisation
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import {GetUrlPrefix} from "../../../../config/HostConfig";

// Build panel constants
const { Option, OptGroup } = Select;

function EditEventModal({ isOpen, eventData, callbackFct}) {

    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const { TextArea } = Input;
    const [isModalOpen, setIsModalOpen] = useState(isOpen);
    const [form] = Form.useForm();

    const [eventList, setEventList] = useState([]);
    const [eventTableData, setEventTableData] = useState();

    const [deviceSensors, setDeviceSensors] = useState([]);
    const [registeredDevices, setRegisteredDevices] = useState([]);
    const [furnaceId, setFurnaceId] = useState(eventData ? eventData.furnace_id : null);

    const [eventSensor, setEventSensor] = useState(eventData ? eventData.sensor_id : []);
    const [precon1Sensor, setPrecon1Sensor] = useState(eventData && eventData.precons[0] ? eventData.precons[0].precon_id : null);
    const [precon2Sensor, setPrecon2Sensor] = useState(eventData && eventData.precons[1] ? eventData.precons[1].precon_id : null);
    const [precon3Sensor, setPrecon3Sensor] = useState(eventData && eventData.precons[2] ? eventData.precons[2].precon_id : null);

    const [eventOptions, setEventOptions] = useState([]);
    const [precon1Options, setPrecon1Options] = useState([]);
    const [precon2Options, setPrecon2Options] = useState([]);
    const [precon3Options, setPrecon3Options] = useState([]);

    const [eventSensorUnit, setEventSensorUnit] = useState(' ');
    const [precon1SensorUnit, setPrecon1SensorUnit] = useState(' ');
    const [precon2SensorUnit, setPrecon2SensorUnit] = useState(' ');
    const [precon3SensorUnit, setPrecon3SensorUnit] = useState(' ');

    const [eventSensorText, setEventSensorText] = useState(null);
    const [precon1SensorText, setPrecon1SensorText] = useState(null);
    const [precon2SensorText, setPrecon2SensorText] = useState(null);
    const [precon3SensorText, setPrecon3SensorText] = useState(null);

    const [isEventSensorBool, setIsEventSensorBool] = useState(true);
    const [isPrecon1SensorBool, setIsPrecon1SensorBool] = useState(true);
    const [isPrecon2SensorBool, setIsPrecon2SensorBool] = useState(true);
    const [isPrecon3SensorBool, setIsPrecon3SensorBool] = useState(true);

    // Get registered devices
    async function getDevices() {
        try {
            let response = await fetch(GetUrlPrefix("device-controller") + `/applications/smart-melt/devices?detailed=true`);
            const jsonData = await response.json();
            setRegisteredDevices(jsonData);
        } catch (error) {
            console.log(error);
        }
    }

    //Get Device Sensors
    async function getDeviceSensors(id) {
        if(id !== undefined) {
            try {
                let response = await fetch(GetUrlPrefix("app-smart-melt") + `/api/sensors/` + id);
                const jsonData = await response.json();
                setDeviceSensors(jsonData);
                console.log(jsonData);

                if (eventData) {
                    initializeSensorOptions(eventData);
                }


            } catch (error) {
                console.log(error);
            }
        }
    }

    const getFurnaceNameById = (id) => {
        const furnace = registeredDevices.find(device => device.id === id);
        return furnace ? furnace.name : 'Unknown';
    };

    const initializeSensorOptions = (data) => {
        handleSensorChange(data.sensor_id, { children: data.sensor_name }, 0);
        if (data.precons[0]) handleSensorChange(data.precons[0].precon_id, { children: data.precons[0].precon_name }, 1);
        if (data.precons[1]) handleSensorChange(data.precons[1].precon_id, { children: data.precons[1].precon_name }, 2);
        if (data.precons[2]) handleSensorChange(data.precons[2].precon_id, { children: data.precons[2].precon_name }, 3);
    };

    const handleSensorChange = (value, key, preconNumber) => {
        console.log(value);
        console.log(key);
        console.log(preconNumber);
        if(value === undefined) {
            if (preconNumber === 0) {
                setEventSensor(' ');
                setEventSensorText(null);
                setEventOptions([]);
                setEventSensorUnit('');
                setIsEventSensorBool(true);
            } else if(preconNumber === 1) {
                setPrecon1Sensor(null);
                setPrecon1SensorText(null);
                setPrecon1Options([]);
                setPrecon1SensorUnit('');
                setIsPrecon1SensorBool(true);
            } else if (preconNumber === 2) {
                setPrecon2Sensor(null);
                setPrecon2SensorText(null);
                setPrecon2Options([]);
                setPrecon2SensorUnit('');
                setIsPrecon2SensorBool(true);
            } else if (preconNumber === 3) {
                setPrecon3Sensor(null);
                setPrecon3SensorText(null);
                setPrecon3Options([]);
                setPrecon3SensorUnit('');
                setIsPrecon3SensorBool(true);
            }
        } else {
            console.log("SensorChange")
            console.log (deviceSensors);
            let selectedSensor;
            if (deviceSensors) {
                selectedSensor = deviceSensors.find(sensor => sensor.sensor_id === value);
            } else return;

            if (selectedSensor) {
                const options = selectedSensor.sensor_type === 'int' ? [
                    { value: 1, label: <><ArrowUpOutlined /> {t('applications.smartmelt.configuration.add.options.type.high')}</> },
                    { value: 0, label: <><ArrowDownOutlined /> {t('applications.smartmelt.configuration.add.options.type.low')}</> }
                ] : [
                    { value: 1, label: <><CheckCircleOutlined /> {t('applications.smartmelt.configuration.add.options.type.true')}</> },
                    { value: 0, label: <><CloseCircleOutlined /> {t('applications.smartmelt.configuration.add.options.type.false')}</> }
                ];

                if (preconNumber === 0) {
                    setEventSensor(value);
                    setEventSensorText(key.children)
                    setEventOptions(options);
                    setEventSensorUnit(selectedSensor.sensor_type === 'int' ? selectedSensor.sensor_unit : '');
                    setIsEventSensorBool(selectedSensor.sensor_type === 'bool');
                } else if(preconNumber === 1) {
                    setPrecon1Sensor(value);
                    setPrecon1SensorText(key.children)
                    setPrecon1Options(options);
                    setPrecon1SensorUnit(selectedSensor.sensor_type === 'int' ? selectedSensor.sensor_unit : '');
                    setIsPrecon1SensorBool(selectedSensor.sensor_type === 'bool');
                } else if (preconNumber === 2) {
                    setPrecon2Sensor(value);
                    setPrecon2SensorText(key.children)
                    setPrecon2Options(options);
                    setPrecon2SensorUnit(selectedSensor.sensor_type === 'int' ? selectedSensor.sensor_unit : '');
                    setIsPrecon2SensorBool(selectedSensor.sensor_type === 'bool');
                } else if (preconNumber === 3) {
                    setPrecon3Sensor(value);
                    setPrecon3SensorText(key.children)
                    setPrecon3Options(options);
                    setPrecon3SensorUnit(selectedSensor.sensor_type === 'int' ? selectedSensor.sensor_unit : '');
                    setIsPrecon3SensorBool(selectedSensor.sensor_type === 'bool');
                }
            }
        }}

        useEffect(() => {
            if (eventData) {
              getDeviceSensors(eventData.furnace_id);
            }
        }, [eventData])

        useEffect(() => {
            if (eventData && deviceSensors) {
                
                setFurnaceId(eventData.furnace_id);

                console.log(eventData)
                handleSensorChange(eventData.sensor_id, {children: ''}, 0 );
                handleSensorChange(eventData.precons[0]?.precon_id, {children: ''}, 1 );
                handleSensorChange(eventData.precons[1]?.precon_id, {children: ''}, 2 );
                handleSensorChange(eventData.precons[2]?.precon_id, {children: ''}, 3 );

                form.setFieldsValue({
                    device: { value: eventData.furnace_id, label: getFurnaceNameById(eventData.furnace_id) },
                    sensor_sensor: { value: eventData.sensor_id, label: eventData.sensor_name },
                    sensor_type: eventData.sensor_error_type,
                    sensor_threshold: eventData.sensor_threshold,
                    delay: eventData.delay,
                    text: eventData.text,
                    precon_1_sensor: eventData.precons[0] ? { value: eventData.precons[0].precon_id, label: eventData.precons[0].precon_name } : null,
                    precon_1_type: eventData.precons[0] ? eventData.precons[0].precon_error_type : null,
                    precon_1_threshold: eventData.precons[0] ? eventData.precons[0].precon_threshold : null,
                    precon_2_sensor: eventData.precons[1] ? { value: eventData.precons[1].precon_id, label: eventData.precons[1].precon_name } : null,
                    precon_2_type: eventData.precons[1] ? eventData.precons[1].precon_error_type : null,
                    precon_2_threshold: eventData.precons[1] ? eventData.precons[1].precon_threshold : null,
                    precon_3_sensor: eventData.precons[2] ? { value: eventData.precons[2].precon_id, label: eventData.precons[2].precon_name } : null,
                    precon_3_type: eventData.precons[2] ? eventData.precons[2].precon_error_type : null,
                    precon_3_threshold: eventData.precons[2] ? eventData.precons[2].precon_threshold : null,
                });
            }
        }, [deviceSensors]);

        useEffect(() => {
            getDevices();
            //getEvents();
            if (furnaceId) {getDeviceSensors(furnaceId)};
        },[isOpen, furnaceId])

        useEffect(() => {
            setIsModalOpen(isOpen);
        }, [isOpen]);

        const handleCancel = () => {
            form.resetFields();
            setIsModalOpen(false);
        };
    
        const handleOk = () => {
            form.validateFields().then((values) => {
                console.log("Form Values", values)

                const eventSensorId = values.sensor_sensor.value || values.sensor_sensor;

                const updatedEvent = {
                    furnace_id: values.device.value,
                    id: eventData.id,
                    delay: values.delay,
                    text: values.text,
                    sensor_id: eventSensorId,
                    sensor_name: null,
                    sensor_type: null,
                    sensor_unit: null,
                    sensor_threshold: values.sensor_threshold,
                    sensor_error_type: values.sensor_type,
                    precons: []
                };
        
                const eventSensor = deviceSensors.find(sensor => sensor.sensor_id === eventSensorId);
                if (eventSensor) {
                    updatedEvent.sensor_name = eventSensor.sensor_name;
                    updatedEvent.sensor_type = eventSensor.sensor_type;
                    updatedEvent.sensor_unit = eventSensor.sensor_unit;
                }
                
                if (precon1Sensor) {
                    const precon1SensorId = values.precon_1_sensor.value || values.precon_1_sensor;
                    console.log("Precon1Sensor: ",precon1Sensor);
                    updatedEvent.precons.push({
                        precon_id: precon1SensorId,
                        precon_name: deviceSensors.find(sensor => sensor.sensor_id === precon1Sensor).sensor_name,
                        precon_type: deviceSensors.find(sensor => sensor.sensor_id === precon1Sensor).sensor_type,
                        precon_unit: deviceSensors.find(sensor => sensor.sensor_id === precon1Sensor).sensor_unit,
                        precon_threshold: values.precon_1_threshold,
                        precon_error_type: values.precon_1_type,
                    });
                }
                if (precon2Sensor) {
                    const precon2SensorId = values.precon_2_sensor.value || values.precon_2_sensor;
                    updatedEvent.precons.push({
                        precon_id: precon2SensorId,
                        precon_name: deviceSensors.find(sensor => sensor.sensor_id === precon2Sensor).sensor_name,
                        precon_type: deviceSensors.find(sensor => sensor.sensor_id === precon2Sensor).sensor_type,
                        precon_unit: deviceSensors.find(sensor => sensor.sensor_id === precon2Sensor).sensor_unit,
                        precon_threshold: values.precon_2_threshold,
                        precon_error_type: values.precon_2_type,
                    });
                }
                if (precon3Sensor) {
                    const precon3SensorId = values.precon_3_sensor.value || values.precon_3_sensor;
                    updatedEvent.precons.push({
                        precon_id: precon3SensorId,
                        precon_name: deviceSensors.find(sensor => sensor.sensor_id === precon3Sensor).sensor_name,
                        precon_type: deviceSensors.find(sensor => sensor.sensor_id === precon3Sensor).sensor_type,
                        precon_unit: deviceSensors.find(sensor => sensor.sensor_id === precon3Sensor).sensor_unit,
                        precon_threshold: values.precon_3_threshold,
                        precon_error_type: values.precon_3_type,
                    });
                }
                console.log("Updated Event",updatedEvent)
                handleEventUpdate(updatedEvent).then(() => {
                    form.resetFields();
                    setIsModalOpen(false);
                    callbackFct();
                })
            });
        };
    
        async function handleEventUpdate(information) {
            try {
                const result = await updateEvent(information);
                if (result === 200) {
                    setIsModalOpen(false);
                    callbackFct();
                    form.resetFields();
                }
            } catch (error) {
                console.error(error);
            }
        }
    
        async function updateEvent(information) {
            return fetch(GetUrlPrefix("app-smart-melt") + `/api/events/` + information.id, {
                headers: {
                    "Content-Type": "application/json"
                },
                method: "PUT",
                body: JSON.stringify(information)
            })
                .then(response => response.status);
        }

        return (
            <>
                <Modal
                    title={t('applications.smartmelt.configuration.tab-edit')}
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={1400}
                >
                
            
                <Form form={form} name="edit-event-form" onFinish={handleOk} layout="vertical">
                    <Row gutter={16}>
                        <Col span={6}>
                            <h3 style={{margin: "0px 0px 8px 0px"}}>{t('applications.smartmelt.configuration.add.headers.event')}</h3>
                            <Form.Item
                                name="device"
                                label={t('applications.smartmelt.configuration.add.labels.device')}
                                rules={[{ 
                                    required: true ,
                                    message: t('applications.smartmelt.configuration.add.labels.device') + " " + t('applications.smartmelt.configuration.add.labels.error')
                                }]}
                            >
                                <Select onChange={(value) => setFurnaceId(value)} value={furnaceId} disabled>
                                    {/* {
                                        devices.map((device) => {
                                        return (<Option key={device.id} value={device.id}>{device.name}</Option>);
                                        })
                                    } */}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="sensor_sensor"
                                label={t('applications.smartmelt.configuration.add.labels.sensor')}
                                rules={[{ 
                                    required: true ,
                                    message: t('applications.smartmelt.configuration.add.labels.sensor') + " " + t('applications.smartmelt.configuration.add.labels.error')
                                }]}
                            >
                                <Select onChange={(value, key) => handleSensorChange(value, key, 0)}>
                                    {
                                        deviceSensors.length > 0 && deviceSensors.map((sensor) => {
                                        return (<Option key={sensor.sensor_id} value={sensor.sensor_id}>{sensor.sensor_name}</Option>);
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="sensor_type"
                                label={t('applications.smartmelt.configuration.add.labels.type')}
                                rules={[{ 
                                    required: true ,
                                    message: t('applications.smartmelt.configuration.add.labels.type') + " " + t('applications.smartmelt.configuration.add.labels.error')
                                }]}
                            >
                                <Select
                                    style={{maxWidth: 160}}  
                                    //options={eventOptions} 
                                >
                                     {
                                        eventOptions.length > 0 && eventOptions.map((option) => {
                                            //console.log(option);
                                            return (<Option key={option.value} value={option.value}>{option.label}</Option>)
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="sensor_threshold"
                                label={t('applications.smartmelt.configuration.add.labels.threshold')}
                                rules={[{ 
                                    required: isEventSensorBool ? false : true ,
                                    message: t('applications.smartmelt.configuration.add.labels.threshold') + " " + t('applications.smartmelt.configuration.add.labels.error')
                                }]}
                            >
                                <InputNumber style={{minWidth: 160}} addonAfter={eventSensorUnit} disabled={isEventSensorBool}/>
                            </Form.Item>
                            <Form.Item
                                name="delay"
                                label={t('applications.smartmelt.configuration.add.labels.delay')}
                                rules={[{ 
                                    required: true ,
                                    message: t('applications.smartmelt.configuration.add.labels.delay') + " " + t('applications.smartmelt.configuration.add.labels.error')
                                }]}
                            >
                                <InputNumber style={{minWidth: 160}}/>
                            </Form.Item>
                            <Form.Item
                                name="text"
                                label={t('applications.smartmelt.configuration.add.labels.text')}
                                rules={[{ 
                                    required: true ,
                                    message: t('applications.smartmelt.configuration.add.labels.text') + " " + t('applications.smartmelt.configuration.add.labels.error')
                                }]}
                            >
                                <TextArea rows={3} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <h3 style={{margin: "0px 0px 8px 0px", height: 89}}>{t('applications.smartmelt.configuration.add.headers.precon-1')}</h3>
                            <div style={{ height: 24 }} />
                            <Form.Item
                                name="precon_1_sensor"
                                label={t('applications.smartmelt.configuration.add.labels.sensor')}
                            >
                                <Select onChange={(value, key) => handleSensorChange(value, key, 1)} allowClear>
                                    {   
                                        deviceSensors.length > 0 && deviceSensors.map((sensor) => {
                                            return (<Option key={sensor.sensor_id} value={sensor.sensor_id}>{sensor.sensor_name}</Option>);
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="precon_1_type"
                                label={t('applications.smartmelt.configuration.add.labels.type')}
                                rules={[{ 
                                    required: !precon1SensorText ? false : true ,
                                    message: t('applications.smartmelt.configuration.add.labels.type') + " " + t('applications.smartmelt.configuration.add.labels.error')
                                }]}
                            >
                                <Select
                                    style={{maxWidth: 160}}  
                                    disabled={!precon1Sensor && !precon1SensorText}
                                    //options={precon1Options} 
                                >
                                    {
                                        precon1Options.length > 0 && precon1Options.map((option) => {
                                            return (<Option key={option.value} value={option.value}>{option.label}</Option>)
                                        })
                                    }

                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="precon_1_threshold"
                                label={t('applications.smartmelt.configuration.add.labels.threshold')}
                                rules={[{ 
                                    required: isPrecon1SensorBool ? false : true ,
                                    message: t('applications.smartmelt.configuration.add.labels.threshold') + " " + t('applications.smartmelt.configuration.add.labels.error')
                                }]}
                            >
                                <InputNumber style={{minWidth: 160}} disabled={isPrecon1SensorBool} addonAfter={precon1SensorUnit}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <h3 style={{margin: "0px 0px 8px 0px", height: 89}}>{t('applications.smartmelt.configuration.add.headers.precon-2')}</h3>
                            <div style={{ height: 24 }} />
                            <Form.Item
                                name="precon_2_sensor"
                                label={t('applications.smartmelt.configuration.add.labels.sensor')}
                            >
                                <Select onChange={(value, key) => handleSensorChange(value, key, 2)} allowClear>
                                    {   
                                        deviceSensors.length > 0 && deviceSensors.map((sensor) => {
                                        return (<Option key={sensor.sensor_id} value={sensor.sensor_id}>{sensor.sensor_name}</Option>);
                                    })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="precon_2_type"
                                label={t('applications.smartmelt.configuration.add.labels.type')}
                                rules={[{ 
                                    required: !precon2SensorText ? false : true ,
                                    message: t('applications.smartmelt.configuration.add.labels.type') + " " + t('applications.smartmelt.configuration.add.labels.error')
                                }]}
                            >
                                <Select
                                    style={{maxWidth: 160}}  
                                    disabled={!precon2Sensor && !precon2SensorText}
                                    options={precon2Options} 
                                />
                            </Form.Item>
                            <Form.Item
                                name="precon_2_threshold"
                                label={t('applications.smartmelt.configuration.add.labels.threshold')}
                                rules={[{ 
                                    required: isPrecon2SensorBool ? false : true ,
                                    message: t('applications.smartmelt.configuration.add.labels.threshold') + " " + t('applications.smartmelt.configuration.add.labels.error')
                                }]}
                            >
                                <InputNumber style={{minWidth: 160}} disabled={isPrecon2SensorBool} addonAfter={precon2SensorUnit}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <h3 style={{margin: "0px 0px 8px 0px", height: 89}}>{t('applications.smartmelt.configuration.add.headers.precon-3')}</h3>
                            <div style={{ height: 24 }} />
                            <Form.Item
                                name="precon_3_sensor"
                                label={t('applications.smartmelt.configuration.add.labels.sensor')}
                            >
                                <Select onChange={(value, key) => handleSensorChange(value, key, 3)} allowClear>
                                    {   
                                        deviceSensors.length > 0 && deviceSensors.map((sensor) => {
                                        return (<Option key={sensor.sensor_id} value={sensor.sensor_id}>{sensor.sensor_name}</Option>);
                                    })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="precon_3_type"
                                label={t('applications.smartmelt.configuration.add.labels.type')}
                                rules={[{ 
                                    required: !precon3SensorText ? false : true ,
                                    message: t('applications.smartmelt.configuration.add.labels.type') + " " + t('applications.smartmelt.configuration.add.labels.error')
                                }]}
                            >
                                <Select
                                    style={{maxWidth: 160}}  
                                    disabled={!precon3Sensor && !precon3SensorText}
                                    options={precon3Options} 
                                />
                            </Form.Item>
                            <Form.Item
                                name="precon_3_threshold"
                                label={t('applications.smartmelt.configuration.add.labels.threshold')}
                                rules={[{ 
                                    required: isPrecon3SensorBool ? false : true ,
                                    message: t('applications.smartmelt.configuration.add.labels.threshold') + " " + t('applications.smartmelt.configuration.add.labels.error')
                                }]}
                            >
                                <InputNumber style={{minWidth: 160}} disabled={isPrecon3SensorBool} addonAfter={precon3SensorUnit}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                </Modal>
            </>
        );
    }
    export default EditEventModal;
