import './WaterCoolingApp.css';
import { Divider, Select, Layout, Button, Modal, Form, Space, Table, DatePicker, Collapse, Statistic, Row, Col, Card, Descriptions } from 'antd';
import React, { useState, useEffect } from 'react';
import {GetUrlPrefix} from '../../../config/HostConfig';

//Localisation
import { useTranslation } from 'react-i18next';

//Build panel constants
const { Header, Content, Footer } = Layout;
const { Option } = Select;

function WaterCoolingAppBasic() {

    //Localisation
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
  
    //Set initial state
    const [furnaceId, setFurnaceId] = useState(1)
    const [form] = Form.useForm();
    const [registeredDevices, setRegisteredDevices] = useState([]);
    const [dashboardId, setDashboardId] = useState ();

    //Get registered devices
    async function getDevices () {
        try {
        let response = await fetch(GetUrlPrefix("device-controller") + `/applications/cooling-water-basic/devices?detailed=true`);
        const jsonData = await response.json();
        let deviceList = new Array()
        /* jsonData.forEach(function(device) {
            deviceList[device.id] = device
        })  */
        /* console.log(deviceList);
        console.log("registeredDevices", registeredDevices) */
        setRegisteredDevices(jsonData)
        } catch (error) {
        console.log(error);
        }
    }

    //Initial page load
    useEffect(() => {
        getDevices();
    }, [])

    const changeFurnace = (value)  => {
        setFurnaceId(value);
    }

    //Set Dashboard ID after device change
    useEffect(() => {
        const deviceProcessArray = [...registeredDevices];
        deviceProcessArray.forEach(( element ) => {
        if (element.id === furnaceId) {
            element.app_attributes.forEach((app_attribute) => {
            if ( app_attribute.identifier === "dashboard_id" && app_attribute.scope ==="cooling-water-basic") {
                setDashboardId(app_attribute.value);
            }
            })
        }
        })
    }, [furnaceId])

    //Set initiasl Dashboard id after fetching device list
    useEffect(() => {
        const deviceProcessArray = [...registeredDevices];
        deviceProcessArray.forEach(( element ) => {
        if (element.id === furnaceId) {
            element.app_attributes.forEach((app_attribute) => {
            if ( app_attribute.identifier === "dashboard_id" && app_attribute.scope ==="cooling-water-basic") {
                setDashboardId(app_attribute.value);
                //console.log(dashboardId)
            }
            })
        }
        })
    }, [registeredDevices])

    return (
        <div className="app">
        <Layout >
        {/* <img src="/img/app-water-cooling.png" style={{width:"100px"}} /> */}
        <Form 
            form={form} 
            name="FurnaceDropdown"
            initialValues={{
            furnace_select: 1,
            }}>
            <Form.Item name="furnace_select" style={{marginBottom: "0px"}}>
            <Select style={{ width: 176, paddingLeft: 16 }}
                onChange={changeFurnace}
            >
                {
                registeredDevices.map((device) => {
                    { return(<Option key={device.id} value={device.id}>{device.name}</Option>)}
                })
                }
            </Select>
            </Form.Item>
        </Form>
        
        <Content style={{ padding: '15px 50px'}}>
        
            <iframe
                style={{minHeight:"720px"}} 
                src= {GetUrlPrefix("device-controller") + `${dashboardId}&kiosk&refresh=10s&from=now-1h&to=now`}
                width="100%" 
                height="100%"
                frameborder="0"
            ></iframe>
            

        </Content>
        <Footer>&copy; {new Date().getFullYear()} ABP Induction</Footer>
        </Layout>
    </div>
    );
}
export default WaterCoolingAppBasic;

