import React from "react";
import {Button, Space, Table} from 'antd';
import {DeleteOutlined} from "@ant-design/icons";

function DeviceMeasurementTable() {

    const columns = [
        {
            title: 'Identifier',
            dataIndex: 'identifier',
            key: 'identifier',
            render: (text, record) => <a href={"/devices/1/measurements/" + record.id}>{text}</a>,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'Connector',
            dataIndex: 'connector',
            key: 'connector',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button icon={<DeleteOutlined />}>Delete</Button>
                    {/*<Button icon={<DeleteOutlined />}>Clone</Button>*/}
                </Space>
            ),
        },
    ];
    const data = [
        {
            id: 1,
            key: '1',
            identifier: 'Weight',
            connector: 'OPCUA',
            description: "Aktuelles Gewicht",
            value: "14567",
        },
    ];

    return (
        <Table columns={columns} dataSource={data} />
    );
}

export default DeviceMeasurementTable;