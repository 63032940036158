import React, {useEffect, useState} from "react";
import {Button, Form, Input, Modal, Space, Table} from 'antd';
import {DeleteOutlined, EditOutlined, SaveOutlined, PlusOutlined} from '@ant-design/icons';

function SqlConfig({device, onError, onSuccess}) {

    const [connectors, setConnectors] = useState([
        {
            id: 1,
            key: 1,
            host: "localhost",
            database: "edge",
            user: "sa",
            password: "admin",
            query: "SELECT * FROM measurements;"
        },
        {
            id: 2,
            key: 2,
            host: "localhost",
            database: "edge",
            user: "sa",
            password: "admin",
            query: "SELECT * FROM config;"
        },
    ])
    const [form] = Form.useForm();

    const onSelectDelete = (connectorId, query) => {
        Modal.confirm({
            title: 'Delete Connector',
            content:
                <>
                    <p>Do you really want to delete Connector <b>{query}</b>?</p>
                    <p>All connected measurements and requests will be deleted.</p>
                </>
            ,
            onOk: () => {
                deleteConnector(connectorId)
            }
        });
    }

    const deleteConnector = (connectorId) => {
        // Send to backend
        // TODO

        // Delete from list
        let tmpConnectors = []
        connectors.forEach(connector => {
            if(connectorId !== connector.id) {
                tmpConnectors.push({
                    id: connector.id,
                    key: connector.key,
                    host: connector.host,
                    database: connector.database,
                    user: connector.user,
                    password: connector.password,
                    query: connector.query
                })
            }
        });
        setConnectors(tmpConnectors)
        // Show pop-up
        onSuccess("Deletion successfully", "Connector successfully deleted")
    }

    const onSelectUpdate = (connectorId, host, database, user, password, query) => {
        Modal.confirm({
            title: 'Update Connector',
            content:
                <>
                    <Input id="sqlHost" defaultValue={host} />
                    <Input id="sqlDatabase" defaultValue={database} />
                    <Input id="sqlUser" defaultValue={user} />
                    <Input id="sqlPassword" defaultValue={password} />
                    <Input id="sqlQuery" defaultValue={query} />
                </>
            ,
            onOk: () => {
                updateConnector(connectorId)
            }
        });
    }

    const updateConnector = (connectorId) => {
        let host = document.getElementById("sqlHost").value
        let database = document.getElementById("sqlDatabase").value
        let user = document.getElementById("sqlUser").value
        let password = document.getElementById("sqlPassword").value
        let query = document.getElementById("sqlQuery").value

        // Send to backend
        // TODO

        // Update list
        let tmpConnectors = [...connectors]
        tmpConnectors.forEach(connector => {
            if(connectorId === connector.id) {
                connector.host = host
                connector.database = database
                connector.user = user
                connector.password = password
                connector.query = query
            }
        });
        setConnectors(tmpConnectors)

        // Show pop-up
        onSuccess("Update successfully", "Connector successfully updated")
    }

    const addConnector = (values) => {
        // Send to backend
        // TODO

        // Add to list
        let tmpConnectors = [...connectors]
        tmpConnectors.push({
            id: connectors.length + 1, // TODO
            key: connectors.length + 1, // TODO
            host: values.host,
            database: values.database,
            user: values.user,
            password: values.password,
            query: values.query

        })
        setConnectors(tmpConnectors)

        // Clear form
        form.resetFields()

        // Show pop-up
        onSuccess("Creation successfully", "Connector successfully created")
    }

    const columns = [
        {
            title: 'Host',
            dataIndex: 'host',
            key: 'host',
            render: (text) => (<>{text}</>)
        },
        {
            title: 'Database',
            dataIndex: 'database',
            key: 'database',
            render: (text) => (<>{text}</>)
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            render: (text) => (<>{text}</>)
        },
        {
            title: 'Password',
            dataIndex: 'password',
            key: 'password',
            render: (text) => (<>{text}</>)
        },
        {
            title: 'Query',
            dataIndex: 'query',
            key: 'query',
            render: (text) => (<>{text}</>)
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        icon={<EditOutlined />} onClick={() => {
                            onSelectUpdate(record.id, record.host, record.database, record.user, record.password, record.query)
                        }}>
                        Update
                    </Button>
                    <Button
                        icon={<DeleteOutlined />} onClick={() => {onSelectDelete(record.id, record.query)}}>
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <div style={{borderRadius: '8px', padding: '5px', marginBottom: '10px'}}>
            <Table
                columns={columns}
                dataSource={connectors}
                pagination={{
                    hideOnSinglePage: true,
                }}

            />
            <h3>Add Connector:</h3>
            <Form
                autoComplete="off"
                initialValues={{
                    deviceId: device.id,
                    host: "",
                    database: "",
                    user: "",
                    password: "",
                    query: "",
                }}
                form={form}
                labelCol={{ span: 4 }}
                name="basic"
                onFinish={addConnector}
                wrapperCol={{ span: 14 }}
            >
                <Form.Item
                    hidden={true}
                    label="Device Id"
                    name="deviceId"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Host"
                    name="host"
                    rules={[{ required: true, message: 'Please input a host!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Database"
                    name="database"
                    rules={[{ required: true, message: 'Please input a database!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="User"
                    name="user"
                    rules={[{ required: true, message: 'Please input an user!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input a password!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Query"
                    name="query"
                    rules={[{ required: true, message: 'Please input a query!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        <SaveOutlined />Save
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default SqlConfig;