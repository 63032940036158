import React, {useEffect, useState} from "react";
import OpcuaConfig from "./connectors/OpcuaConfig";
import {Button, Collapse, notification, Switch} from "antd";
import {CheckCircleOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import SqlConfig from "./connectors/SqlConfig";
const { Panel } = Collapse;

function DeviceConnectorsOverview({device}) {

    const openSuccessNotification = (successMessage, successDescription) => {
        notification.open({
            message: successMessage,
            description: successDescription,
            icon: (
                <CheckCircleOutlined style={{
                    color: 'green',
                }}
                />
            ),
        });
    };

    const openErrorNotification = (errorMessage, errorDescription) => {
        notification.open({
            message: "Update failed",
            description: errorMessage,
            icon: (
                <ExclamationCircleOutlined style={{
                    color: 'red',
                }}
                />
            ),
        });
    };

    useEffect(() => {
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <Collapse>
                <Panel header="OPCUA Connector" key="opcua">
                    <OpcuaConfig device={device} onError={openErrorNotification} onSuccess={openSuccessNotification} />
                </Panel>
                <Panel header="SQL Connector" key="sql">
                    <SqlConfig device={device} onError={openErrorNotification} onSuccess={openSuccessNotification} />
                </Panel>
            </Collapse>
        </div>
    );
}

export default DeviceConnectorsOverview;