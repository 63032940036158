import * as React from "react";

const Light = ({ id, input }) => {

    var bgColor = "#DCDCDC"
    var shadow = ""

    switch (input) {
        case 1:
            switch(id) {
                case 1:
                    bgColor = "#00FF00";
                    shadow = "0 0 6em #33ff33";
                    break;
                    default:
            }
            break;
        case 2:
            switch(id) {
                case 2:
                    bgColor = "#FFFF00";
                    shadow = "0 0 6em #ffff33";
                    break;
            }
            break;
        case 3:
            switch(id) {
                case 3:
                    bgColor = "#FF0000";
                    shadow = "0 0 6em #ff3333";
                    break;
            }
            break;
        default:
    }
    
    return( 
        <div
            style={{
            appearance: "none",
            position: "relative",
            width: 120,
            height: 120,
            marginTop: 20,
            marginBottom: 20,
            marginLeft: 40,
            marginRight: 40,
            backgroundColor: bgColor,
            boxShadow: shadow,
            verticalAlign: "middle",
            borderRadius: "100%",
            display: "block", 
            }}
        >
            &nbsp;
        </div>
    );
}

export default Light;
