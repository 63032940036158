import './SmartMeltApp.css';
import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Divider, Select, Layout, Button, Modal, Form, Space, Table, DatePicker} from 'antd';
import {
  InfoCircleFilled,
  CheckCircleOutlined,
  CommentOutlined,
  ConsoleSqlOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Timer from './components/Timer';
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import SmartMeltConfigModal from './components/ConfigModal';

//Localisation
import { useTranslation } from 'react-i18next';
import Statistics from "./components/Statistics";
import {GetUrlPrefix, GetWebsocketProtocol} from "../../../config/HostConfig";

dayjs.extend(customParseFormat);
dayjs.extend(weekday)
dayjs.extend(localeData)

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

//Build panel constants
const { Header, Content, Footer } = Layout;

const testData = [
  {
      "active": true,    
      "date": "2022-12-30T13:15:38.385959+01:00",
      "furnace_id": 1,                
      "text": "Haube offen",        
      "actual_delay": 4,  
      "losses": 210, 
  },
 {
      "active": false,    
      "date": "2022-12-30T13:15:38.385959+01:00",
      "furnace_id": 1,                
      "text": "Haube offen",       
      "actual_delay": 34,  
      "losses": 210,
  }
]

function AppSmartMeltGuide() {

  //----------------------------Localisation----------------------
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

const dayData= [
    {
        "id": 0,
        "deviceID": 0,
        "eventTyp": 0,
        "date": "1900-01-01T00:00:00+01:00",
        "duration": 0,
        "realValue": 0,
        "targetValue": 0,
        "calculatedLosses": 0,
        "selectedReason": "1"
    }
]


  //Set initial state
  const [registeredDevices, setRegisteredDevices]=useState([])
  const [form] = Form.useForm();
  const [fromTime, setFromTime]=useState(moment().subtract(6, 'days').startOf('day').format());// store time from
  const [toTime, setToTime]=useState(moment().endOf('day').format()); // store time to
  const [msgData, setMsgData] = useState([])
  const [shownRows, setShownRows] = useState(msgData)
  const dateFormat = 'YYYY/MM/DD';

  //ReasonModal
  const [selectedReasonData, setSelectedReasonData] = useState(-8);
  const [reasonEventData, setReasonEventData] = useState();
  const [reasonDateData, setReasonDateData] = useState();
  const [keyData, setKeyData] = useState();
  const [isReasonModalVisible, setIsReasonModalVisible] = useState(false);
  const [realValueData, setRealValueData] = useState();
  const [targetValueData, setTargetValueData] = useState();
  const [lossesData, setLossesData] = useState();
  const [realValueString, setRealValueString] = useState('');
  const [targetValueString, setTargetValueString] = useState('');
  const [durationString, setDurationString] = useState('');
  const [furnaceData, setFurnaceData] = useState();

  //InfoModal
  const [infoEventData, setInfoEventData] = useState();
  const [infoDateData, setInfoDateData] = useState();
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);

  //Config Modal
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(0);

  //Live Table
  const [liveData, setLiveData] = useState([]);
  const [activeEvents, setActiveEvents] = useState([]);
  const [archivedEvents, setArchivedEvents] = useState([]);

  const c = useRef(null);

  //Possible reasons to choose
  const reasonData = [
    {id: 1, visible: true},
    {id: 2, visible: true},
    {id: 3, visible: true},
    {id: 4, visible: true},
    {id: 5, visible: true}
  ]

  //device filter function
  const getDeviceFilter = () => {
    let deviceFilter = []

    registeredDevices.forEach(function(regDevice) {
      deviceFilter.push({
        text: regDevice.name,
        value: regDevice.id,
      })
    });
    return deviceFilter
  }

  const openConfigModal = () => {
    setIsConfigModalOpen(isConfigModalOpen + 1)
  }

  //Table for live events
  const liveColumns = [
    {
      title: t('applications.smartmelt.furnace'),
      dataIndex: 'furnace_id',
      key: 'furnace_id',
      render: (deviceId) => <div>{getDeviceName(deviceId)}</div>,
      filters: getDeviceFilter(),
      onFilter: (value, record) => {
        return record.furnace_id === value;
      },
    },
    {
      title: t('applications.smartmelt.event'),
      dataIndex: 'text',
      key: 'text',
    },
    /* {
      title: t('applications.smartmelt.targetValue'),
      dataIndex: 'targetValue',
      key: 'targetValue',
    }, */
    {
      title: t('applications.smartmelt.duration'),
      dataIndex: 'actual_delay',
      key: 'actual_delay',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      render: (active) => <div>{active ? "active" : "inactive"}</div>,
      key: 'active',
      hidden: true,
      filters:[
        {
          text: 'active',
          value: true,
        },
        {
          text: 'inactive',
          value: false,
        }
      ],
      defaultFilteredValue: ['true'],
      onFilter: (value, record) => {
        return record.active === value;
      }
    }
  ].filter(item => !item.hidden);

  //Get matching date format
  const setDateFormat = (lng) => {
    switch(lng) {
      case 'en':
        return 'YYYY/MM/DD hh:mm:ss a';
      case 'de':
        return 'DD.MM.YYYY HH:mm:ss';
      default:
        return 'YYYY/MM/DD hh:mm:ss a';
    }
  }

  //Table for archived events
  const eventColumns = [
    {
      title: t('applications.smartmelt.date'),
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
      render: (date) => <div>{dayjs(date).format(setDateFormat(i18n.language))}</div>,
      defaultSortOrder: 'descend'
    },
    {
      title: t('applications.smartmelt.furnace'),
      dataIndex: 'furnace_id',
      key: 'furnace_id',
      sorter: (a, b) => a.furnace_id > b.furnace_id,
      render: (furnace_id) => <div>{getDeviceName(furnace_id)}</div>,
      filters: getDeviceFilter(),
      onFilter: (value, record) => {
        return record.furnace_id === value;
      },
    },
    {
      title: t('applications.smartmelt.event'),
      key: 'text',
      dataIndex: 'text'
      /* dataIndex: 'eventTyp',
      render: (type) => <div>{t('applications.smartmelt.events.' + type)}</div>,
      key: 'eventTyp',
      filters:[
        {
          text: t('applications.smartmelt.events.0'),
          value: 0,
        },
        {
          text: t('applications.smartmelt.events.1'),
          value: 1,
        },
        {
          text: t('applications.smartmelt.events.2'),
          value: 2,
        },
        {
          text: t('applications.smartmelt.events.3'),
          value: 3,
        },
        {
          text: t('applications.smartmelt.events.4'),
          value: 4,
        },
        {
          text: t('applications.smartmelt.events.5'),
          value: 5,
        },
        {
          text: t('applications.smartmelt.events.6'),
          value: 6,
        },
      ],
      onFilter: (value, record) => {
        return record.eventTyp === value;
      } */

    },
    /* {
      title: t('applications.smartmelt.hasReason'),
      dataIndex: 'selectedReason',
      key: 'selectedReason',
      render: (selectedReason) => <div>{t('applications.smartmelt.reasons.' + selectedReason)}</div>,
    }, */
    {
      title: t('applications.smartmelt.duration'),
      dataIndex: 'actual_delay',
      key: 'actual_delay',
      /* render: (duration) => <div>{getDurationString(duration)}</div> */
    },
    {
      title: t('applications.smartmelt.calculatedLosses'),
      dataIndex: 'losses',
      key: 'losses',
      render: (text) => <div>{text} kWh </div>,
      sorter: (a, b) => a.calculatedLosses > b.calculatedLosses,
    },
   /*  {
      title: t('applications.smartmelt.action'),
      key: 'action',
      align: 'center',
      render: (_, record) => (
          <Space size="middle">
            <a><InfoCircleFilled style={{fontSize: '24px', color: '#f5835d' }} onClick={() => {showInfoModal(record.date, record.event)}}/></a>
            <a><CheckCircleOutlined style={{fontSize: '24px', color: '#49b407' }}/></a>
            <a><CommentOutlined style={{fontSize: '24px', color: '#137dee' }} /></a>
          </Space>
      ),
    }, */
  ];

  

  //Get registered devices
  async function getDevices () {
    try {
      console.log("GETURL:", GetUrlPrefix(""))
      console.log("GETURLERGEBNIS:", GetUrlPrefix("") + `/applications/smart-melt/devices?detailed=true`)
      let response = await fetch(GetUrlPrefix("device-controller") + `/applications/smart-melt/devices?detailed=true`);
      const jsonData = await response.json();
      setRegisteredDevices(jsonData)
    } catch (error) {
      console.log(error);
    }
  }

  /* async function getDevices () {
    try {
      let response = await fetch(appHost + `:25100/devices`);
      const jsonData = await response.json();
      let deviceList = []
      jsonData.forEach(function(device) {
        deviceList[device.id] = device
      })
      setRegisteredDevices(deviceList)
    } catch (error) {
      console.log(error);
    }
  } */

  //Get device names
  const getDeviceName = (deviceId) => {
    const device = registeredDevices.find(device => device.id === deviceId);
    if (device) {
      return device.name;
    } else {
      console.log(registeredDevices);
      return "Unknown Id";
    }
  }

  // Websocket
  useEffect(() => {
    let url = "ws://192.168.178.170:25304/ws"
    //let url = GetWebsocketProtocol() + ':25304/ws';
    c.current = new WebSocket(url);
    c.current.onopen = function () {
      console.log('WS connected');
    };
    c.current.onmessage = function (msg) {
      let data;
      try {
        data = JSON.parse(msg.data);
        //console.log("Data", data)
      } catch (e) {
        console.error("Failed to parse WebSocket message as JSON:", e);
        return;
      }
     if (data === null || data.length === 0) {
        setActiveEvents([]);
        setArchivedEvents([]);
        setMsgData([]);
     }
     else if (Array.isArray(data)) {
        const activeEvents = data.filter(event => event.active);
        const archivedEvents = data.filter(event => !event.active);
        setActiveEvents(activeEvents);
        setArchivedEvents(archivedEvents);
      } else {
        console.error("Received data is not an array:", data);
        
      }
    }
    getDevices();
  }, []);

  
  // Get stored events
  const getArchiveData = (from, to) => {
    if (!c.current || c.current.readyState !== WebSocket.OPEN) {
      console.error("WebSocket is not open");
      return;
    }
    // Set time range
    let tempFrom = dayjs(from).startOf('day').format().toString();
    let tempTo = dayjs(to).endOf('day').format().toString();
    // Parse
    from = encodeURIComponent(tempFrom);
    to = encodeURIComponent(tempTo);

    const message = {
      from: tempFrom,
      to: tempTo
    };

    c.current.send(JSON.stringify(message));
  


   /*  try {
      let response;
      if(from === "" || to === "") {
        response = await fetch(GetUrlPrefix("app-smart-melt") + `/api/events/archive?from=${from}&to=${to}`);
        
      } else {
        response = await fetch(GetUrlPrefix("app-smart-melt") + `/api/events/archive?from=${from}&to=${to}`);
      } */
      /* const jsonData = await response.json();
      
      jsonData.forEach(function(element){
        element.key = element.id;
        if(element.selectedReason === ""){
          element.selectedReason = 0;
        }
      })
      setMsgData(jsonData); */
    /* } catch (error) {
      console.log(error);
    } */
  }



  //Handle date range change
  function handleCalendar(value){ 
    if(value != null && !value.includes(null)){
      const from = value[0].format();
      const to = value[1].format();
      setFromTime(from);
      setToTime(to);
    }
  }

  const handleInfoOk = () => {
     setIsInfoModalVisible(false);
  };

  const getDurationString = (secDuration) => {
    var minutes = Math.floor(secDuration / 60);
    var seconds = secDuration - minutes * 60;
    return minutes + ":" + seconds + " min";
  }

  const getValueString = (event, value) => {
    switch(event) {
      case 0:
        return  value + " kW";
      case 1:
        return value + " kW";
      case 2: { 
        if (value == 0) {
          return t('applications.smartmelt.events.notFullfilled')
        } else if (value == 1) { 
          return t('applications.smartmelt.events.fullfilled')};
      }
      case 3: { 
        if (value == 0) {
          return t('applications.smartmelt.events.notFullfilled')
        } else if (value == 1) { 
          return t('applications.smartmelt.events.fullfilled')};
      }
      case 4: { 
        if (value == 0) {
          return t('applications.smartmelt.events.notFullfilled')
        } else if (value == 1) { 
          return t('applications.smartmelt.events.fullfilled')};
      }
      case 5:
        return value + " °C";
      case 6: { 
        if (value == 0) {
          return t('applications.smartmelt.events.notFullfilled')
        } else if (value == 1) { 
          return t('applications.smartmelt.events.fullfilled')};
      }
      default:
        return "";
    };  
  }

  const showReasonModal = (selectedReason, event, date, key, realValue, targetValue, calculatedLosses, duration, deviceID) => {
    setFurnaceData(getDeviceName(deviceID));
    setDurationString(getDurationString(duration));
    setRealValueString(getValueString(event, realValue));
    setTargetValueString(getValueString(event, targetValue));
    setKeyData(key);
    setSelectedReasonData(selectedReason);
    setReasonEventData(t('applications.smartmelt.events.' + event));
    setReasonDateData(dayjs(date).format(setDateFormat(i18n.language)));
    setIsReasonModalVisible(true);
    setRealValueData(realValue);
    setTargetValueData(targetValue);
    setLossesData(calculatedLosses);
    if (selectedReason == 0) {
      form.setFieldsValue({Selection: ""})
    } else {
      form.setFieldsValue({Selection: parseInt(selectedReason)})
    }
  };

  //Handle selected reason change
  const getFormularInput = async (selectedReason, key) => {
    let res = await fetch(GetUrlPrefix("app-smart-melt") + '/api/reason', {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        //eventId: values.id,
        id: key,
        selectedReason: selectedReason + "",
      }),
    })
  }

  const handleReasonOk = async () => {
    form.validateFields()
			.then((values) => {
				//console.log(values.Selection)
        //console.log(keyData)
        getFormularInput(values.Selection, keyData)
        changeSelectedReason(values.Selection, keyData)
        setIsReasonModalVisible(false);
				})
			.catch((errorInfo) => {});
    
  };

  // const writeMsgData = () => {
  //   console.log(fromTime);
  //   console.log(toTime)
  // }

  const changeSelectedReason = (reason, idToChange) => {
    const newMsgData = [...msgData];
    for (var i = 0; i < newMsgData.length; i++){
      if (newMsgData[i].id == idToChange){
        newMsgData[i].selectedReason = reason;
      }
    }
    setMsgData(newMsgData);
  }

  const onChangeEvent = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    setShownRows(extra.currentDataSource)
  };
  const onChangeLive = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  useEffect(() => {
    // const newMsgData = [...msgData];
    setShownRows(msgData)
    
  }, [msgData])

  
  return (
    <div className="app">
    <Layout >
    {/* <Header className="header">
      <div className="header-content">
        <img className="header-image" src={"abp_logo.png"}  alt={"ABP Logo"}/>
        <h1 style={{color:'#f5835d'}}>Smart Melt</h1>
      </div>
     <Select
        showArrow
        defaultValue={i18n.language}
        style={{ width: 100 }}
        onChange={(value)=>changeLanguage(value)}
      >
        <Option value="de" key="de">DE</Option>
        <Option value="en"key="en">EN</Option>
      </Select>
    </Header> */}
    <Content style={{ padding: '15px 50px', overflowY: "auto", flex: 1}}>
      <Space>
        <Statistics shownRows={archivedEvents} />
        <Button type="primary" onClick={() => openConfigModal()}>{t('applications.smartmelt.configuration.title')}</Button>
      </Space>
      <Table 
        columns={liveColumns} 
        scroll={{x: true}}
        dataSource={activeEvents} 
        onChange={onChangeLive}
        pagination={false} 
        rowClassName={"red"} 
        style={{ paddingBottom: '6px'}}
        title={() => t('applications.smartmelt.ongoingEvents')} 
      />
      <div style={{ flexDirection: 'row', display: "flex", alignItems: "center", marginBottom: "0px"}}>
        <h2 style={{marginRight: "15px"}}>Filter:</h2>
        <RangePicker
          defaultValue={[dayjs(fromTime), dayjs(toTime)]}
          format={"YYYY MMMM DD"}
          style={{ marginRight: '15px'}}
          onCalendarChange={
            (value)=>{
              handleCalendar(value)
            }
          }
        />
        <Button key="submit" type="primary" onClick={() => getArchiveData (fromTime, toTime)}>Ok</Button>
      </div>
      <Table 
        style={{marginBottom: 50}}
        columns={eventColumns} 
        scroll={{x: true}}
        dataSource={archivedEvents}
        onChange={onChangeEvent}
        title={() => t('applications.smartmelt.archivedEvents')}
        rowClassName={(record, index) => (record.selectedReason != 0 ? "black" : "red")}
        /* onRow={(record, rowIndex) => {
          return {
            onClick: () => showReasonModal(
              record.selectedReason, 
              record.eventTyp, 
              record.date, 
              record.id, 
              record.realValue, 
              record.targetValue, 
              record.calculatedLosses, 
              record.duration,
              record.deviceID
            )
          };
        }
        } */
      />
        <Modal
          title={infoDateData}
          visible={isInfoModalVisible}
          closable={false}
          footer={[
            <Button key="submit" type="primary" onClick={handleInfoOk}>
              OK
            </Button>,
          ]}
        >
          <p><i>{infoEventData}</i></p>
          <p>Approximate energy that could have been saved: <b>15 kWh</b></p>
        </Modal>
        <Modal
          title={reasonDateData}
          visible={isReasonModalVisible}
          closable={true}
          onCancel={() => {setIsReasonModalVisible(false)}}
          footer={[
            <Button key="submit" type="primary" onClick={handleReasonOk}>
              OK
            </Button>,
          ]}
        >
          <h2>{reasonEventData}</h2>
          <Divider />
          <p><b>{t('applications.smartmelt.furnace')} :</b> {furnaceData}</p>
          <p><b>{t('applications.smartmelt.reasonModalRealValue')} :</b> {realValueString}</p>
          <p><b>{t('applications.smartmelt.reasonModalTargetValue')} :</b> {targetValueString}</p>
          <p><b>{t('applications.smartmelt.duration')} :</b> {durationString}</p>
          <p><b>{t('applications.smartmelt.reasonModalLosses')} :</b> {lossesData} kWh</p>
          <Divider />
          <p>{t('applications.smartmelt.selectionTitle')}</p>
          <Form form={form} name="ReasonSubmitForm">
            <Form.Item
              name="Selection"
              rules={[
                {
                  required: true,
                  message: t('applications.smartmelt.requiredFieldMessage'),
                }
              ]}
            >
              <Select
                //defaultValue= {selectedReasonData > 0 ? selectedReasonData : ""}
                style={{
                  width: 350,
                }}
              >
                {
                  reasonData.map((reason)=>{
                    if (reason.visible) { return(<Option key={reason.id} value={reason.id}>{t('applications.smartmelt.reasons.' + reason.id)}</Option>);}
                  }
                  )
                }
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <SmartMeltConfigModal isOpen={isConfigModalOpen} /> 
    </Content>
    <Footer>&copy; {new Date().getFullYear()} ABP Induction</Footer>
    </Layout>
   </div>
  );
}

export default AppSmartMeltGuide;
