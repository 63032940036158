import React, {useEffect, useState} from "react";
import {Table, Tag} from 'antd';
import { useTranslation } from 'react-i18next';
import {GetUrlPrefix} from "../../config/HostConfig";

function AppInstallationsTable() {

    const [registeredDevices, setRegisteredDevices] = useState([]);
    const [data, setData] = useState([]);

    //Localisation
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    // Get registered devices
    async function getDevices() {
        try {
        let response = await fetch(GetUrlPrefix("device-controller") + `/devices`);
        const jsonData = await response.json();
        setRegisteredDevices(jsonData);
        } catch (error) {
        console.log(error);
        }
    }

    useEffect(() => {
        getDevices();
        setData([
            {
                key: 'condition-monitoring',
                installStatus: true,
                activations: {
                    1: true,
                    2: true,
                }
            },
            {
                key: 'crucible-index',
                installStatus: true,
                activations: {
                    1: true,
                    2: false
                }
            },
            {
                key: 'kpi-app',
                installStatus: false,
                activations: {
                    1: false,
                    2: false
                }
            },
            {
                key: 'opticharge',
                installStatus: false,
                activations: {
                    1: false,
                    2: false
                }
            }
        ]);
    }, []);
   

    const sendStatusUpdate = async (app_identifier, device_id, app_status) => {
        const url = GetUrlPrefix("device-controller") + "/updateStatus";
        const body = {
            app_identifier,
            device_id,
            app_status
        };

        try {
            let response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            console.log(jsonData);
        } catch (error) {
            console.log('Failed to update status:', error);
        }
    };

    const handleTagClick = (app_identifier, device_id, app_status) => {
        setData((prevData) => {
            return prevData.map((row) => {
                if (row.key === app_identifier) {
                    const newActivations = {
                        ...row.activations,
                        [device_id]: app_status === 'active'
                    };
                    sendStatusUpdate(app_identifier, device_id, app_status);
                    return {
                        ...row,
                        activations: newActivations
                    };
                }
                return row;
            });
        });
    };


    const columns = [
        {
            title: 'Applications',
            dataIndex: 'key',
            key: 'application',
            render: (key) => {
                let title =""
                switch(key) {
                    case "condition-monitoring": 
                        title="Condition Monitoring";
                        break;
                    case "crucible-index": 
                        title="Crucible Index";
                        break;
                    case "kpi-app": 
                        title="KPI App";
                        break;
                    case "opticharge": 
                        title="OptiCharge";
                        break;
                    case "pouring-support": 
                        title="Pouring Support";
                        break;
                    case "smart-melt": 
                        title="Pouring Support";
                        break;
                    case "smart-recipe": 
                        title="Pouring Support";
                        break;
                    case "cooling-water-basic": 
                        title="Cooling Water Basic";
                        break;
                    case "cooling-water-pro": 
                        title="Cooling Water Pro";
                        break;
                    case "indutrack": 
                        title="InduTrack";
                        break;
                    default:
                        break;
                }
                return title;
            }
        },
        {
            title: 'Installation Status',
            dataIndex: 'installStatus',
            key: 'installStatus',
            render: (installStatus) => (
                <Tag color={installStatus ? 'blue' : '#D8D8D8'}>
                    {installStatus ? 'INSTALLED' : 'NOT INSTALLED'}
                </Tag>
            ),
        },
        ...registeredDevices.map((device, index) => ({
            title: device.name,
            dataIndex: `device${index + 1}`,
            key: `device${index + 1}`,
            render: (_, record) => {
                const device_id = index + 1;
                const isActive = record.activations[device_id];
                return record.installStatus ? (
                    <>
                        <Tag
                            color={isActive ? 'green' : '#D8D8D8'}
                            onClick={() => handleTagClick(record.key, device_id, 'active')}
                            style={{ cursor: 'pointer' }}
                        >
                            ACTIVE
                        </Tag>
                        <Tag
                            color={!isActive ? 'volcano' : '#D8D8D8'}
                            onClick={() => handleTagClick(record.key, device_id, 'inactive')}
                            style={{ cursor: 'pointer' }}
                        >
                            INACTIVE
                        </Tag>
                    </>
                ) : null;
            }
        }))
    ];

    return (
        <div>
            <Table columns={columns} dataSource={data}></Table>
        </div>
    );

} export default AppInstallationsTable;