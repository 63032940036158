import React, {useEffect, useState} from "react";
import {Button, Space, Table} from 'antd';
import {DeleteOutlined} from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

function DeviceAttributeTable({attributes, device, onSelectDelete}) {

    //Localisation
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const [formattedAttributes, setFormattedAttributes] = useState([])

    const mapAttributes = (rawData) => {
        let attributes = []
        Object.keys(rawData).forEach(key => {
            attributes.push({
                id: rawData[key].id,
                deviceId: rawData[key].deviceId,
                key: rawData[key].id,
                identifier: rawData[key].identifier,
                name: rawData[key].name,
                description: rawData[key].description,
                dataType: rawData[key].dataType,
                value: rawData[key].value,
            })
        });
        setFormattedAttributes(attributes)
    }

    const getDataTypeLabel = (dataType) => {
        switch (dataType) {
            case "boolean": return "Boolean"
            case "float": return "Float"
            case "number": return "Number"
            case "string": return "String"
            default: return "unknown"
        }
    }

    const columns = [
        {
            title: t('devices.devicePage.attributes.table.id'),
            dataIndex: 'identifier',
            key: 'identifier',
            render: (text, record) => <a href={"/devices/" + device.id + "/attributes/" + record.id}>{text}</a>,
        },
        {
            title: t('devices.devicePage.attributes.table.name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('devices.devicePage.attributes.table.description'),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: t('devices.devicePage.attributes.table.dataType'),
            dataIndex: 'dataType',
            key: 'dataType',
            render: (text) => getDataTypeLabel(text)
        },
        {
            title: t('devices.devicePage.attributes.table.value'),
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: t('devices.devicePage.attributes.table.action'),
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        icon={<DeleteOutlined />} onClick={() => {onSelectDelete(record.deviceId, record.id, record.identifier, record.name)}}>
                        {t('buttons.delete')}
                    </Button>
                    {t('buttons.clone')}
                </Space>
            ),
        },
    ];

    useEffect(() => {
        mapAttributes(attributes)
    }, [attributes])

    return (
        <Table columns={columns} dataSource={formattedAttributes} />
    );
}

export default DeviceAttributeTable;