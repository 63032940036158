import React from "react";
import AppCard from "../components/apps/AppCard";

function AppPage() {


    return (
        <div style={{ display: "flex", flexWrap: "wrap"}}>
            <AppCard name="Condition Monitoring" port="condition-monitoring" url="condition-monitoring" logo="app-condition-monitoring.png" available={false} />
            <AppCard name="Crucible Guard" port="25303" url="crucible-guard" logo="app-crucible-guard.png" available={false} />
            <AppCard name="KPI" port="kpi" url="kpi-app" logo="app-kpi.png" available={false} />
            <AppCard name="OptiCharge Guide" url="opticharge-guide" port="25305" logo="app-opticharge-guide.png" available={true} />
            <AppCard name="Pouring Support" url="app-pouring-support" port="25306" logo="app-pouring-support.png" available={false} />
            <AppCard name="SmartMelt Guide" url="smartmelt-guide" port="25304" logo="app-smartmelt-guide.png" available={true} />
            <AppCard name="Smart Recipe" url="app-smart-recipe" port="25307" logo="app-smart-recipe.png" available={false} />
            {/* <AppCard name="Cooling Water Basic" port="watercooling-basic" logo="app-water-cooling.png" available={true} /> */}
            {/* <AppCard name="Cooling Water Pro" port="watercooling-pro" logo="app-water-cooling.png" available={true} /> */}
            <AppCard name="Cooling Guard" url="cooling-guard" port="25308" logo="app-cooling-guard.png" available={true} />
            {/*<AppCard name="PLATZHALTER" port="PLATZHALTER" logo="PLATZHALTER.png" available={true} />*/}
            <AppCard name="Inductor Guard" url="inductor-guard" port="25313" logo="app-inductor-guard.png" available={false} />
            <AppCard name="Collaboration Guide" url="collaboration-guide" port="" logo="app-collaboration-guide.png" available={false} />
        </div>
    );
}

export default AppPage;