import { Form, Input, InputNumber, Modal, Popconfirm, Button, Tabs, Table, Select, Row, Col } from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined
  } from '@ant-design/icons';
import { useEffect, useState } from "react";
import EditEventModal from './EditEventModal'

//Localisation
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import {GetUrlPrefix} from "../../../../config/HostConfig";

// Build panel constants
const { Option, OptGroup } = Select;

const data3 = {
    1:  {
            furnace_id: 1,                // int
            id: 1,                    //int, ID for the Event/Error
            delay: 12,                //int, in minutes
            text: "Haube offen",       //String 
            sensor_id: 1,             //int
            sensor_name: "Haube",  //String
            sensor_type: "int",       //String [int, bool] 
            sensor_unit: "°",         //String, empty when sensor_type=bool  
            sensor_threshold: 20,    //int, null when sensor_type=bool
            sensor_error_type: 1,     //int,                   
            precons: [                
            {
                precon_id: 2,
                precon_name: "Umrichter an",  //see above
                precon_type: "bool",      
                precon_unit: "",
                precon_threshold: null,
                precon_error_type: 1,
            },
            {
                precon_id: 3,
                precon_name: "Kühlung an",  //see above
                precon_type: "bool",      
                precon_unit: "",
                precon_threshold: null,
                precon_error_type: 0,
            },
            ]
        },
    2: {
        furnace_id: 2,                // int
        id: 2,                    //int, ID for the Event/Error
        delay: 5,                //int, in minutes
        text: "Temperatur zu hoch",       //String 
        sensor_id: 4,             //int
        sensor_name: "Temperatursensor",  //String
        sensor_type: "int",       //String [int, bool] 
        sensor_unit: "°C",         //String, empty when sensor_type=bool  
        sensor_threshold: 1500,    //int, null when sensor_type=bool
        sensor_error_type: 1,     //int,                   
        precons: [                
            {
            precon_id: 1,
            precon_name: "Umrichter an",  //see above
            precon_type: "bool",      
            precon_unit: "",
            precon_threshold: null,
            precon_error_type: 1,
            },
        ]
    },
}

const flattenData = (data) => {
    const flatData = [];
    Object.keys(data).forEach(key => {
        const event = data[key];
        if (event && typeof event === 'object') {
            flatData.push({
                furnace_id: event.furnace_id,
                key: event.id,
                id: event.id,
                delay: event.delay,
                text: event.text,
                sensor_name: event.sensor_name,
                sensor_threshold: event.sensor_threshold,
                sensor_unit: event.sensor_unit,
                sensor_type: event.sensor_type,
                sensor_error_type: event.sensor_error_type,
                precons: event.precons.map(precon => ({
                    key: precon.precon_id,
                    precon_name: precon.precon_name,
                    precon_threshold: precon.precon_threshold,
                    precon_unit: precon.precon_unit,
                    precon_type: precon.precon_type,
                    precon_error_type: precon.precon_error_type,
                })),
            });
        } else {
            console.error(`Data for key ${key} is not a valid event object`, data[key]);
        }
    });
    console.log(flatData);
    return flatData;
};

const testData = flattenData(data3);

function SmartMeltConfigModal({ isOpen }) {

    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const [isEditModalOpen, setIsEditModalOpen] = useState(0);

    const { TextArea } = Input;
    const [isModalOpen, setIsModalOpen] = useState(isOpen);
    const [form] = Form.useForm();

    const [eventList, setEventList] = useState([]);
    const [eventTableData, setEventTableData] = useState([]);

    const [deviceSensors, setDeviceSensors] = useState([]);
    const [deviceList, setDeviceList] = useState([]);
    const [furnaceId, setFurnaceId] = useState();

    const [eventSensor, setEventSensor] = useState([])
    const [precon1Sensor, setPrecon1Sensor] = useState(null);
    const [precon2Sensor, setPrecon2Sensor] = useState(null);
    const [precon3Sensor, setPrecon3Sensor] = useState(null);

    const [eventOptions, setEventOptions] = useState([]);
    const [precon1Options, setPrecon1Options] = useState([]);
    const [precon2Options, setPrecon2Options] = useState([]);
    const [precon3Options, setPrecon3Options] = useState([]);

    const [eventSensorUnit, setEventSensorUnit] = useState(' ');
    const [precon1SensorUnit, setPrecon1SensorUnit] = useState(' ');
    const [precon2SensorUnit, setPrecon2SensorUnit] = useState(' ');
    const [precon3SensorUnit, setPrecon3SensorUnit] = useState(' ');

    const [eventSensorText, setEventSensorText] = useState(null);
    const [precon1SensorText, setPrecon1SensorText] = useState(null);
    const [precon2SensorText, setPrecon2SensorText] = useState(null);
    const [precon3SensorText, setPrecon3SensorText] = useState(null);

    const [isEventSensorBool, setIsEventSensorBool] = useState(true);
    const [isPrecon1SensorBool, setIsPrecon1SensorBool] = useState(true);
    const [isPrecon2SensorBool, setIsPrecon2SensorBool] = useState(true);
    const [isPrecon3SensorBool, setIsPrecon3SensorBool] = useState(true);

    const [editEventData, setEditEventData] = useState(null);
    
    /* const layout = {
        labelCol: {
            span: 5,
        },
        wrapperCol: {
            span: 19,
        },
    }; */

    const openEditModal = () => {
        setIsEditModalOpen(isEditModalOpen + 1)
      }

    //Get Devices
    async function getDevices () {
        try {
            let response = await fetch(GetUrlPrefix("device-controller") + `/applications/smart-melt/devices?detailed=true`);
            const jsonData = await response.json();
            setDeviceList(jsonData);
            if (jsonData.length > 0) {
                const minFurnaceId = Math.min(...jsonData.map(device => device.id));
                setFurnaceId(minFurnaceId);
                form.setFieldsValue({ device: minFurnaceId });
            }
        } catch (error) {
        console.log(error);
        }
    }

    //Get all existing Events
    async function getEvents () {
        try {
            let response = await fetch(GetUrlPrefix("app-smart-melt") + `/api/events`);
            const jsonData = await response.json();
            setEventList(jsonData);
            if (jsonData.length > 0) {
                const flattenedData = flattenData(jsonData);
                setEventTableData(flattenedData);
            }
        } catch (error) {
        console.log(error);
        }
    }

    //Get Device Sensors
    async function getDeviceSensors(id) {
        if(id !== undefined) {
            try {
                let response = await fetch(GetUrlPrefix("app-smart-melt") + `/api/sensors/` + id);
                const jsonData = await response.json();
                setDeviceSensors(jsonData);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const changeFurnace = (value) => {
        setFurnaceId(value)
    }

    const handleSensorChange = (value, key, preconNumber) => {
        console.log(value, key, preconNumber);
        if(value === undefined) {
            if (preconNumber === 0) {
                setEventSensor(' ');
                setEventSensorText(null);
                setEventOptions([]);
                setEventSensorUnit('');
                setIsEventSensorBool(true);
            } else if(preconNumber === 1) {
                setPrecon1Sensor(null);
                setPrecon1SensorText(null);
                setPrecon1Options([]);
                setPrecon1SensorUnit('');
                setIsPrecon1SensorBool(true);
            } else if (preconNumber === 2) {
                setPrecon2Sensor(null);
                setPrecon2SensorText(null);
                setPrecon2Options([]);
                setPrecon2SensorUnit('');
                setIsPrecon2SensorBool(true);
            } else if (preconNumber === 3) {
                setPrecon3Sensor(null);
                setPrecon3SensorText(null);
                setPrecon3Options([]);
                setPrecon3SensorUnit('');
                setIsPrecon3SensorBool(true);
            }
        } else {
            console.log("SensorChange")
            const selectedSensor = deviceSensors.find(sensor => sensor.sensor_id === value);
            const options = selectedSensor.sensor_type === 'int' ? [
                { value: 1, label: <><ArrowUpOutlined /> {t('applications.smartmelt.configuration.add.options.type.high')}</> },
                { value: 0, label: <><ArrowDownOutlined /> {t('applications.smartmelt.configuration.add.options.type.low')}</> }
            ] : [
                { value: 1, label: <><CheckCircleOutlined /> {t('applications.smartmelt.configuration.add.options.type.true')}</> },
                { value: 0, label: <><CloseCircleOutlined /> {t('applications.smartmelt.configuration.add.options.type.false')}</> }
            ];

            if (preconNumber === 0) {
                setEventSensor(value);
                setEventSensorText(key.children)
                setEventOptions(options);
                setEventSensorUnit(selectedSensor.sensor_type === 'int' ? selectedSensor.sensor_unit : '');
                setIsEventSensorBool(selectedSensor.sensor_type === 'bool');
            } else if(preconNumber === 1) {
                setPrecon1Sensor(value);
                setPrecon1SensorText(key.children)
                setPrecon1Options(options);
                setPrecon1SensorUnit(selectedSensor.sensor_type === 'int' ? selectedSensor.sensor_unit : '');
                setIsPrecon1SensorBool(selectedSensor.sensor_type === 'bool');
            } else if (preconNumber === 2) {
                setPrecon2Sensor(value);
                setPrecon2SensorText(key.children)
                setPrecon2Options(options);
                setPrecon2SensorUnit(selectedSensor.sensor_type === 'int' ? selectedSensor.sensor_unit : '');
                setIsPrecon2SensorBool(selectedSensor.sensor_type === 'bool');
            } else if (preconNumber === 3) {
                setPrecon3Sensor(value);
                setPrecon3SensorText(key.children)
                setPrecon3Options(options);
                setPrecon3SensorUnit(selectedSensor.sensor_type === 'int' ? selectedSensor.sensor_unit : '');
                setIsPrecon3SensorBool(selectedSensor.sensor_type === 'bool');
            }
        }}

    useEffect(() => {
        getDevices();
        getEvents();
    },[isOpen])

    useEffect(() => {
        getDeviceSensors(furnaceId);
    },[furnaceId])

    const getFurnaceNameById = (id) => {
        const furnace = deviceList.find(device => device.id === id);
        return furnace ? furnace.name : 'Unknown';
    };

    const columns = [
        {
            title: t('applications.smartmelt.configuration.table.columns.device'),
            key: 'device',
            render: (text, record) => (
                getFurnaceNameById(record.furnace_id)
            ), 
        },
        {
            title: t('applications.smartmelt.configuration.table.columns.group-sensor'),
            children: [
                {
                    title: t('applications.smartmelt.configuration.table.columns.sensor.sensor'),
                    dataIndex: 'sensor_name',
                    key: 'sensor_name',
                },
                {
                    title: t('applications.smartmelt.configuration.table.columns.sensor.threshold'),
                    dataIndex: 'sensor_threshold',
                    key: 'sensor_threshold',
                    render: (text, record) => {
                        if (record.sensor_type === "int" && record.sensor_threshold !== null) {
                        return `${record.sensor_threshold} ${record.sensor_unit}`
                        } else return "-" 
                    },
                },
                {
                    title: t('applications.smartmelt.configuration.table.columns.sensor.type'),
                    key: 'deviation_type',
                    render: (text, record) => {
                        if (record.sensor_type === 'int' && record.sensor_error_type === 1) {
                            return <><ArrowUpOutlined /> {t('applications.smartmelt.configuration.add.options.type.high')}</>;
                        } else if (record.sensor_type === 'int' && record.sensor_error_type === 0) {
                            return <><ArrowDownOutlined /> {t('applications.smartmelt.configuration.add.options.type.low')}</>;
                        } else if (record.sensor_type === 'bool' && record.sensor_error_type === 1) {
                            return <><CheckCircleOutlined /> {t('applications.smartmelt.configuration.add.options.type.true')}</>;
                        } else if (record.sensor_type === 'bool' && record.sensor_error_type === 0) {
                            return <><CloseCircleOutlined /> {t('applications.smartmelt.configuration.add.options.type.false')}</>;
                        }
                        return '';
                    },
                },
            ],
        },
        /* {
            title: t('applications.smartmelt.configuration.table.columns.group-precon'),
            children: [
                {
                    title: t('applications.smartmelt.configuration.table.columns.precon.sensor'),
                    dataIndex: 'precon_name',
                    key: 'precon_name',
                },
                {
                    title: t('applications.smartmelt.configuration.table.columns.precon.threshold'),
                    dataIndex: 'precon_threshold',
                    key: 'precon_threshold',
                },
                {
                    title: t('applications.smartmelt.configuration.table.columns.precon.type'),
                    key: 'precon_type',
                    render: (text, record) => (
                           record.precon_type
                    ),
                },
            ]
        }, */
        {
            title: t('applications.smartmelt.configuration.table.columns.delay'),
            dataIndex: 'delay',
            key: 'delay',
        },
        {
            title: t('applications.smartmelt.configuration.table.columns.text'),
            dataIndex: 'text',
            key: 'text',
        },
        {
            title: t('applications.smartmelt.configuration.table.columns.action'),
            key: 'action',
            render: (text, record) => (
                <>
                    <Popconfirm title="Wirklich löschen?" onConfirm={() => handleDelete(record.id)}>
                        <Button danger style={{marginRight: "4px"}}><DeleteOutlined /></Button>
                    </Popconfirm>
                    <Button onClick={() => handleEdit(record)}><EditOutlined /></Button>
                </>
            ),
        },
    ];

    const expandedRowRender = (record) => {
        const preconColumns = [
            {
                title: t('applications.smartmelt.configuration.table.columns.precon.sensor'),
                dataIndex: 'precon_name',
                key: 'precon_name',
            },
            {
                title: t('applications.smartmelt.configuration.table.columns.precon.threshold'),
                key: 'precon_threshold',
                render: (text, record) => {
                    if (record.precon_threshold !== null && record.precon_type === "int") {
                    return `${record.precon_threshold} ${record.precon_unit}`
                    } else return "-" 
                },

            },
            {
                title: t('applications.smartmelt.configuration.table.columns.precon.type'),
                key: 'precon_type',
                // key: 'deviation_type',
                render: (text, record) => {
                    if (record.precon_type === 'int' && record.precon_error_type === 1) {
                        return <><ArrowUpOutlined /> {t('applications.smartmelt.configuration.add.options.type.high')}</>
                    } else if (record.precon_type === 'int' && record.precon_error_type === 0) {
                        return <><ArrowDownOutlined /> {t('applications.smartmelt.configuration.add.options.type.low')}</>;
                    } else if (record.precon_type === 'bool' && record.precon_error_type === 1) {
                        return <><CheckCircleOutlined /> {t('applications.smartmelt.configuration.add.options.type.true')}</>;
                    } else if (record.precon_type === 'bool' && record.precon_error_type === 0) {
                        return <><CloseCircleOutlined /> {t('applications.smartmelt.configuration.add.options.type.false')}</>;
                    }
                    return '';
                },
            },
        ];

        return <Table columns={preconColumns} dataSource={record.precons} pagination={false} />;
    };


    useEffect(() => {
        setIsModalOpen(isOpen);
    }, [isOpen]);

    const handleOk = () => {
        form.validateFields().then((values) => {
            const newEvent = {
                furnace_id: values.device,
                id: 0,  
                delay: values.delay,
                text: values.text,
                sensor_id: values.sensor_sensor,
                sensor_name: deviceSensors.find(sensor => sensor.sensor_id === values.sensor_sensor).sensor_name,
                sensor_type: deviceSensors.find(sensor => sensor.sensor_id === values.sensor_sensor).sensor_type,
                sensor_unit: deviceSensors.find(sensor => sensor.sensor_id === values.sensor_sensor).sensor_unit,
                sensor_threshold: values.sensor_threshold,
                sensor_error_type: values.sensor_type,
                precons: []
            };
            
            if (precon1Sensor) {
                newEvent.precons.push({
                    precon_id: values.precon_1_sensor,
                    precon_name: deviceSensors.find(sensor => sensor.sensor_id === precon1Sensor).sensor_name,
                    precon_type: deviceSensors.find(sensor => sensor.sensor_id === precon1Sensor).sensor_type,
                    precon_unit: deviceSensors.find(sensor => sensor.sensor_id === precon1Sensor).sensor_unit,
                    precon_threshold: values.precon_1_threshold,
                    precon_error_type: values.precon_1_type,
                });
            }
            if (precon2Sensor) {
                newEvent.precons.push({
                    precon_id: values.precon_2_sensor,
                    precon_name: deviceSensors.find(sensor => sensor.sensor_id === precon2Sensor).sensor_name,
                    precon_type: deviceSensors.find(sensor => sensor.sensor_id === precon2Sensor).sensor_type,
                    precon_unit: deviceSensors.find(sensor => sensor.sensor_id === precon2Sensor).sensor_unit,
                    precon_threshold: values.precon_2_threshold,
                    precon_error_type: values.precon_2_type,
                });
            }
            if (precon3Sensor) {
                newEvent.precons.push({
                    precon_id: values.precon_3_sensor,
                    precon_name: deviceSensors.find(sensor => sensor.sensor_id === precon3Sensor).sensor_name,
                    precon_type: deviceSensors.find(sensor => sensor.sensor_id === precon3Sensor).sensor_type,
                    precon_unit: deviceSensors.find(sensor => sensor.sensor_id === precon3Sensor).sensor_unit,
                    precon_threshold: values.precon_3_threshold,
                    precon_error_type: values.precon_3_type,
                });
            }
            handleEventCreation(newEvent).then(() => {
                getEvents();
                form.resetFields();
                setIsModalOpen(false);
            })
            
        });
    };

    async function handleEventCreation(information) {
        try {
            const result = await createEvent(information);
            if (result === 200) {
                setIsModalOpen(false);
                form.resetFields();
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function createEvent(information) {
        return fetch(GetUrlPrefix("app-smart-melt") + `/api/events/0`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify(information)
        })
            .then(response => response.status);
    }

    async function deleteEvent(id) {
        return fetch(GetUrlPrefix("app-smart-melt") + `/api/events/` + id, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "DELETE",
        })
            .then(response => response.status);
    }

    

    const onFinish = (values) => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleDelete = (key) => {
        deleteEvent(key).then(() => {
            setTimeout(() => {
                getEvents();
            }, 1000);
        });
    };

    const handleEdit = (record) => {
        const eventToEdit = eventList.find(event => event.id === record.id);
        console.log(eventToEdit)
        setEditEventData(eventToEdit);
        openEditModal();
    }

    const items = [
        {
          key: '1',
          label: t('applications.smartmelt.configuration.tab-table'),
          children: <Table 
            columns={columns} 
            dataSource={eventTableData}
            expandable={{expandedRowRender}} 
            pagination={{pageSize: 8}} />,
        },
        {
          key: '2',
          label: t('applications.smartmelt.configuration.tab-add'),
          children: 
            <Form  form={form} name="control-hooks" onFinish={handleOk} layout="vertical">
                <Row gutter={16}>
                    <Col span={6}>
                        <h3 style={{margin: "0px 0px 8px 0px"}}>{t('applications.smartmelt.configuration.add.headers.event')}</h3>
                        <Form.Item
                            name="device"
                            label={t('applications.smartmelt.configuration.add.labels.device')}
                            rules={[{ 
                                required: true ,
                                message: t('applications.smartmelt.configuration.add.labels.device') + " " + t('applications.smartmelt.configuration.add.labels.error')
                            }]}
                        >
                            <Select 
                                onChange={changeFurnace} 
                                value={furnaceId}
                            >
                                {
                                    deviceList.map((device) => {
                                    return (<Option key={device.id} value={device.id}>{device.name}</Option>);
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="sensor_sensor"
                            label={t('applications.smartmelt.configuration.add.labels.sensor')}
                            rules={[{ 
                                required: true ,
                                message: t('applications.smartmelt.configuration.add.labels.sensor') + " " + t('applications.smartmelt.configuration.add.labels.error')
                            }]}
                        >
                            <Select onChange={(value, key) => handleSensorChange(value, key, 0)}>
                                {
                                    deviceSensors.map((sensor) => {
                                    return (<Option key={sensor.sensor_id} value={sensor.sensor_id}>{sensor.sensor_name}</Option>);
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="sensor_type"
                            label={t('applications.smartmelt.configuration.add.labels.type')}
                            rules={[{ 
                                required: true ,
                                message: t('applications.smartmelt.configuration.add.labels.type') + " " + t('applications.smartmelt.configuration.add.labels.error')
                            }]}
                        >
                            <Select
                                style={{maxWidth: 160}}  
                                options={eventOptions} 
                            />
                        </Form.Item>
                        <Form.Item
                            name="sensor_threshold"
                            label={t('applications.smartmelt.configuration.add.labels.threshold')}
                            rules={[{ 
                                required: isEventSensorBool ? false : true ,
                                message: t('applications.smartmelt.configuration.add.labels.threshold') + " " + t('applications.smartmelt.configuration.add.labels.error')
                            }]}
                        >
                            <InputNumber style={{minWidth: 160}} addonAfter={eventSensorUnit} disabled={isEventSensorBool}/>
                        </Form.Item>
                        <Form.Item
                            name="delay"
                            label={t('applications.smartmelt.configuration.add.labels.delay')}
                            rules={[{ 
                                required: true ,
                                message: t('applications.smartmelt.configuration.add.labels.delay') + " " + t('applications.smartmelt.configuration.add.labels.error')
                            }]}
                        >
                            <InputNumber style={{minWidth: 160}}/>
                        </Form.Item>
                        <Form.Item
                            name="text"
                            label={t('applications.smartmelt.configuration.add.labels.text')}
                            rules={[{ 
                                required: true ,
                                message: t('applications.smartmelt.configuration.add.labels.text') + " " + t('applications.smartmelt.configuration.add.labels.error')
                            }]}
                        >
                            <TextArea rows={3} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <h3 style={{margin: "0px 0px 8px 0px", height: 89}}>{t('applications.smartmelt.configuration.add.headers.precon-1')}</h3>
                        <div style={{ height: 24 }} />
                        <Form.Item
                            name="precon_1_sensor"
                            label={t('applications.smartmelt.configuration.add.labels.sensor')}
                        >
                            <Select onChange={(value, key) => handleSensorChange(value, key, 1)} allowClear>
                                {   
                                    deviceSensors.map((sensor) => {
                                        return (<Option key={sensor.sensor_id} value={sensor.sensor_id}>{sensor.sensor_name}</Option>);
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="precon_1_type"
                            label={t('applications.smartmelt.configuration.add.labels.type')}
                            rules={[{ 
                                required: !precon1SensorText ? false : true ,
                                message: t('applications.smartmelt.configuration.add.labels.type') + " " + t('applications.smartmelt.configuration.add.labels.error')
                            }]}
                        >
                            <Select
                                style={{maxWidth: 160}}  
                                disabled={!precon1Sensor && !precon1SensorText}
                                options={precon1Options} 
                            />
                        </Form.Item>
                        <Form.Item
                            name="precon_1_threshold"
                            label={t('applications.smartmelt.configuration.add.labels.threshold')}
                            rules={[{ 
                                required: isPrecon1SensorBool ? false : true ,
                                message: t('applications.smartmelt.configuration.add.labels.threshold') + " " + t('applications.smartmelt.configuration.add.labels.error')
                            }]}
                        >
                            <InputNumber style={{minWidth: 160}} disabled={isPrecon1SensorBool} addonAfter={precon1SensorUnit}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <h3 style={{margin: "0px 0px 8px 0px", height: 89}}>{t('applications.smartmelt.configuration.add.headers.precon-2')}</h3>
                        <div style={{ height: 24 }} />
                        <Form.Item
                            name="precon_2_sensor"
                            label={t('applications.smartmelt.configuration.add.labels.sensor')}
                        >
                            <Select onChange={(value, key) => handleSensorChange(value, key, 2)} allowClear>
                                {   
                                deviceSensors.map((sensor) => {
                                    return (<Option key={sensor.sensor_id} value={sensor.sensor_id}>{sensor.sensor_name}</Option>);
                                })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="precon_2_type"
                            label={t('applications.smartmelt.configuration.add.labels.type')}
                            rules={[{ 
                                required: !precon2SensorText ? false : true ,
                                message: t('applications.smartmelt.configuration.add.labels.type') + " " + t('applications.smartmelt.configuration.add.labels.error')
                            }]}
                        >
                            <Select
                                style={{maxWidth: 160}}  
                                disabled={!precon2Sensor && !precon2SensorText}
                                options={precon2Options} 
                            />
                        </Form.Item>
                        <Form.Item
                            name="precon_2_threshold"
                            label={t('applications.smartmelt.configuration.add.labels.threshold')}
                            rules={[{ 
                                required: isPrecon2SensorBool ? false : true ,
                                message: t('applications.smartmelt.configuration.add.labels.threshold') + " " + t('applications.smartmelt.configuration.add.labels.error')
                            }]}
                        >
                            <InputNumber style={{minWidth: 160}} disabled={isPrecon2SensorBool} addonAfter={precon2SensorUnit}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <h3 style={{margin: "0px 0px 8px 0px", height: 89}}>{t('applications.smartmelt.configuration.add.headers.precon-3')}</h3>
                        <div style={{ height: 24 }} />
                        <Form.Item
                            name="precon_3_sensor"
                            label={t('applications.smartmelt.configuration.add.labels.sensor')}
                        >
                            <Select onChange={(value, key) => handleSensorChange(value, key, 3)} allowClear>
                                {   
                                deviceSensors.map((sensor) => {
                                    return (<Option key={sensor.sensor_id} value={sensor.sensor_id}>{sensor.sensor_name}</Option>);
                                })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="precon_3_type"
                            label={t('applications.smartmelt.configuration.add.labels.type')}
                            rules={[{ 
                                required: !precon3SensorText ? false : true ,
                                message: t('applications.smartmelt.configuration.add.labels.type') + " " + t('applications.smartmelt.configuration.add.labels.error')
                            }]}
                        >
                            <Select
                                style={{maxWidth: 160}}  
                                disabled={!precon3Sensor && !precon3SensorText}
                                options={precon3Options} 
                            />
                        </Form.Item>
                        <Form.Item
                            name="precon_3_threshold"
                            label={t('applications.smartmelt.configuration.add.labels.threshold')}
                            rules={[{ 
                                required: isPrecon3SensorBool ? false : true ,
                                message: t('applications.smartmelt.configuration.add.labels.threshold') + " " + t('applications.smartmelt.configuration.add.labels.error')
                            }]}
                        >
                            <InputNumber style={{minWidth: 160}} disabled={isPrecon3SensorBool} addonAfter={precon3SensorUnit}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>,
        },
      ];

      const refreshEvents = async () => {
        setTimeout(() => {
            getEvents();
        }, 1000);
    };

   

    return (
        <>
            <Modal
              title={t('applications.smartmelt.configuration.title')}
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              width={1400}
            >
                <Tabs type="card" defaultActiveKey="1" items={items} />
            </Modal>
            <EditEventModal 
                isOpen={isEditModalOpen}
                eventData={editEventData}
                callbackFct={refreshEvents}
                //devices={deviceList} 
            />
        </>
    );
}
export default SmartMeltConfigModal;
