import React, {useEffect, useState} from "react";
import {Button, Form, Input, notification, Select, Divider} from 'antd';
import {CheckCircleOutlined, ExclamationCircleOutlined, SaveOutlined, GlobalOutlined} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {GetUrlPrefix} from "../../../config/HostConfig";

const testData = [
    'condition-monitoring',
    'crucible-index', 
    'kpi-app', 
    'opticharge', 
    'pouring-support', 
    'smart-melt', 
    'smart-recipe', 
    'cooling-water-basic', 
    'cooling-water-pro', 
    'indutrack',          
]

function DeviceAttributeAddForm({isVisible, onCancel, onAttributeAdded, deviceId}) {

    //Localisation
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const { Option } = Select;

    const [applications, setApplications] = useState(testData);
    const [applicationOptions, setApplicationOptions] = useState([]);
    const [form] = Form.useForm();

    /* async function getApplications() {
        try {
          let response = await fetch(appHostPrefix + appHostLocation + `:25100/applications`);
          const jsonData = await response.json();
          setApplications(jsonData);
        } catch (error) {
          console.log(error);
        }
    } */

    function buildApplicationOptions(applicationList) {
        return applicationList.map((item) => {
            let label;
            switch (item) {
                case "condition-monitoring":
                    label = "Condition Monitoring";
                    break;
                case "crucible-index":
                    label = "Crucible Index";
                    break;
                case "kpi-app":
                    label = "KPI App";
                    break;
                case "opticharge":
                    label = "OptiCharge";
                    break;
                case "pouring-support":
                    label = "Pouring Support";
                    break;
                case "smart-melt":
                    label = "Smart Melt";
                    break;
                case "smart-recipe":
                    label = "Smart Recipe";
                    break;
                case "cooling-water-basic":
                    label = "Cooling Water Basic";
                    break;
                case "cooling-water-pro":
                    label = "Cooling Water Pro";
                    break;
                case "indutrack":
                    label = "InduTrack";
                    break;
                default:
                    label = item; 
                    break;
            }
            return {
                label: label,
                value: item
            };   
        });
    }


    const onFinish = (values) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                identifier: values.identifier,
                name: values.name,
                description: values.description,
                dataType: values.dataType,
                value: values.value,
                app: values.app,
            })
        };
        fetch(GetUrlPrefix("device-controller") + `/devices/` + deviceId + "/attributes", requestOptions)
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    response.text().then(message => {
                        openErrorNotification(message)
                    })
                }
            })
            .then((data => {
                onAttributeAdded(data)
                openSuccessNotification()
            }))
    };

    const openSuccessNotification = () => {
        notification.open({
            message: t('popups.attribute.createMsg.title'),
            description: t('popups.attribute.createMsg.message'),
            icon: (
                <CheckCircleOutlined style={{
                    color: 'green',
                }}
                />
            ),
        });
    };

    const openErrorNotification = (errorMessage) => {
        notification.open({
            message: t('popups.attribute.createMsgFail.title'),
            description: errorMessage,
            icon: (
                <ExclamationCircleOutlined style={{
                    color: 'red',
                }}
                />
            ),
        });
    };

    const toggleVisibility = () => {
        const element = document.getElementById("deviceAddForm");
        if (isVisible) {
            element.classList.remove("hidden");
        } else {
            element.classList.add("hidden");

        }
    }

    useEffect(() => {
        //getApplications()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        toggleVisibility()
        // eslint-disable-next-line
    }, [isVisible])

    useEffect(() => {
        const options = buildApplicationOptions(applications);
        options.unshift({label:<><GlobalOutlined /> <b>{t('devices.devicePage.attributes.addUpdateForm.all-apps')}</b></>, value: "all-applications"})
        setApplicationOptions(options);
        // eslint-disable-next-line
    }, [applications])

    

    return (
        <div id="deviceAddForm" style={{background: '#fff', borderRadius: '8px', padding: '5px', marginBottom: '10px'}}>
            <h2 style={{marginLeft: '10px'}}>{t('devices.devicePage.attributes.addUpdateForm.title')}</h2>
            <Form
            form={form}
                autoComplete="off"
                initialValues={{
                    deviceId: deviceId,
                }}
                labelCol={{ span: 4 }}
                name="basic"
                onFinish={onFinish}
                wrapperCol={{ span: 14 }}
            >
                <Form.Item
                    hidden={true}
                    label="Device Id"
                    name="deviceId"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label= {t('devices.devicePage.attributes.addUpdateForm.id')}
                    name="identifier"
                    rules={[{ required: true, message: t('devices.devicePage.attributes.addUpdateForm.errMsgId') }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label= {t('devices.devicePage.attributes.addUpdateForm.name')}
                    name="name"
                    rules={[{ required: true, message: t('devices.devicePage.attributes.addUpdateForm.errMsgName') }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label= {t('devices.devicePage.attributes.addUpdateForm.description')}
                    name="description"
                    /* rules={[{ required: true, message: t('devices.devicePage.attributes.addUpdateForm.errMsgDesc') }]} */
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label= {t('devices.devicePage.attributes.addUpdateForm.dataType')}
                    name="dataType"
                    rules={[{ required: true, message: t('devices.devicePage.attributes.addUpdateForm.errMsgType') }]}
                >
                    <Select
                        options={[
                            {
                                value: 'boolean',
                                label: 'Boolean',
                            },
                            {
                                value: 'float',
                                label: 'Float',
                            },
                            {
                                value: 'number',
                                label: 'Number',
                            },
                            {
                                value: 'string',
                                label: 'String',
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    label= {t('devices.devicePage.attributes.addUpdateForm.value')}
                    name="value"
                    rules={[{ required: true, message: t('devices.devicePage.attributes.addUpdateForm.errMsgValue') }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('devices.devicePage.attributes.addUpdateForm.app')}
                    name="app"
                    rules={[{ required: true, message: t('devices.devicePage.attributes.addUpdateForm.errMsgApp') }]}
                >
                    <Select
                        options={applicationOptions}
                    />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary" htmlType="submit" style={{marginRight: '10px'}}>
                        <SaveOutlined />{t('buttons.save')}
                    </Button>
                    <Button type="default" onClick={() => {onCancel()}}>
                        {t('buttons.cancel')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default DeviceAttributeAddForm;