import React from 'react';
import { useStopwatch } from 'react-timer-hook';

function Timer() {

  const offsetTimestamp = new Date(); 
  offsetTimestamp.setSeconds(offsetTimestamp.getSeconds() + 30000);
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
    
  } = useStopwatch({ autoStart: true, offsetTimestamp });


  return (
    <div style={{textAlign: 'center'}}>
        <span>{minutes}</span>:<span>{seconds}</span> min
    </div>
  );
} export default Timer;