import React, {useEffect} from "react";
import { Button, Form, Input, notification } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {GetUrlPrefix} from "../../config/HostConfig";

function DeviceEditForm({deviceVal, onUpdate}) {

    //Localisation
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const [form] = Form.useForm()

    const onFinish = (values) => {
       console.log("Values:", values)

        const requestOptions = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: values.name,
                description: values.description,
                mqtt_name: values.mqtt_name,
                system_mqtt_name: values.system_mqtt_name,
            })
        };
        fetch(GetUrlPrefix("device-controller") + `/devices/` + values.id, requestOptions)
            .then(response => {
                if (response.ok) {
                    onUpdate(values)
                    return openSuccessNotification()
                } else {
                    return openErrorNotification()
                }
            })
            .catch(error => {
                console.log(error)
                return openErrorNotification()
            })
    };

    const openSuccessNotification = () => {
        notification.open({
            message: t('popups.device.updateMsg.title'),
            description: t('popups.device.updateMsg.message'),
            icon: (
                <CheckCircleOutlined style={{
                    color: 'green',
                }}
                />
            ),
        });
    };

    const openErrorNotification = () => {
        notification.open({
            message: t('popups.device.updateMsgFail.title'),
            description: t('popups.device.updateMsgFail.message'),
            icon: (
                <ExclamationCircleOutlined style={{
                    color: 'red',
                }}
                />
            ),
        });
    };

    useEffect(() => {
        if (typeof deviceVal !== 'undefined' && deviceVal.length !== 0) {
            form.resetFields();
        }
        // eslint-disable-next-line
    }, [deviceVal])

    return (
        <Form
            autoComplete="off"
            form={form}
            initialValues={{
                id: deviceVal.id,
                name: deviceVal.name,
                description: deviceVal.description,
                mqtt_name: deviceVal.mqtt_name,
                system_mqtt_name: deviceVal.system_mqtt_name,
            }}
            labelCol={{ span: 4 }}
            name="basic"
            onFinish={onFinish}
            wrapperCol={{ span: 14 }}
        >
            <Form.Item
                hidden={true}
                label="Id"
                name="id"
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label= {t('devices.devicePage.details.form.name')}
                name="name"
                rules={[{ required: true, message: 'Please input a name!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label= {t('devices.devicePage.details.form.description')}
                name="description"
                rules={[{ required: true, message: 'Please input a description!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label= {t('devices.devicePage.details.form.mqttName')}
                name="mqtt_name"
                rules={[{ required: true, message: 'Please input a MQTT Name!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label= {t('devices.devicePage.details.form.systemMqttName')}
                name="system_mqtt_name"
                rules={[{ required: true, message: 'Please input a MQTT Name for the system!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <Button type="primary" htmlType="submit">
                    <SaveOutlined />{t('buttons.save')}
                </Button>
            </Form.Item>
        </Form>
    );
}

export default DeviceEditForm;