import './ConditionMonitoringApp.css';
import React, { useState, useEffect } from 'react';
//import 'antd/dist/antd.css'; 
import { Select, Layout, Card, Form } from 'antd';
import Light from "./components/Light";

const { Option } = Select;

//Build panel constants
const { Header, Content, Footer } = Layout;

function ConditionMonitoringApp() {

  

  //Set initial State

  const [furnaceId, setFurnaceId] = useState (1)

  const [signalData, setSignalData] = useState([])
  const [instruction, setInstruction] = useState("")

  

  return (
    <div className="app" style={{ marginLeft: 9, marginRight: 9 }}>
      <Layout>
        <Content style={{ padding: '25px 50px' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '25px', justifyContent: 'center' }}>
            <Card title="Primärer Leistungsschalter" headStyle={{ textAlign: 'center', fontSize: '28px' }} style={{ flex: '0 0 30%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Light id={1} input={0} />
              </div>
              <div style={{ textAlign: 'center' }}><h1 style={{ fontSize: '35px' }}>Aus</h1></div>
            </Card>
            <Card title="Ofen 1 einschaltbereit" headStyle={{ textAlign: 'center', fontSize: '28px' }} style={{ flex: '0 0 30%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Light id={3} input={3} />
              </div>
              <div style={{ textAlign: 'center' }}><h1 style={{ fontSize: '35px' }}>Nicht Bereit</h1></div>
            </Card>
            <Card title="Ofen 2 einschaltbereit" headStyle={{ textAlign: 'center', fontSize: '28px' }} style={{ flex: '0 0 30%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Light id={3} input={3} />
              </div>
              <div style={{ textAlign: 'center' }}><h1 style={{ fontSize: '35px' }}>Nicht Bereit</h1></div>
            </Card>
            <Card title="Umrichter einschaltbereit" headStyle={{ textAlign: 'center', fontSize: '28px' }} style={{ flex: '0 0 30%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Light id={3} input={3} />
              </div>
              <div style={{ textAlign: 'center' }}><h1 style={{ fontSize: '35px' }}>Nicht Bereit</h1></div>
            </Card>
            <Card title="Ofen 1 eingeschaltet" headStyle={{ textAlign: 'center', fontSize: '28px' }} style={{ flex: '0 0 30%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Light id={1} input={0} />
              </div>
              <div style={{ textAlign: 'center' }}><h1 style={{ fontSize: '35px' }}>Aus</h1></div>
            </Card>
            <Card title="Ofen 2 eingeschaltet" headStyle={{ textAlign: 'center', fontSize: '28px' }} style={{ flex: '0 0 30%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Light id={1} input={0} />
              </div>
              <div style={{ textAlign: 'center' }}><h1 style={{ fontSize: '35px' }}>Aus</h1></div>
            </Card>
          </div>
        </Content>
        <Footer>&copy; {new Date().getFullYear()} ABP Induction</Footer>
      </Layout>
    </div>
  );
}

export default ConditionMonitoringApp;