import React, {useEffect, useState} from "react";
import DeviceTable from "../../components/devices/DeviceTable";
import {Button, Modal, notification} from "antd";
import {CheckCircleOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons";
import DeviceAddForm from "../../components/devices/DeviceAddForm";
import {GetUrlPrefix} from "../../config/HostConfig";
import { useTranslation } from 'react-i18next';

function DevicePage() {

    //Localisation
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const [formIsVisible, setFormIsVisible] = useState(false)
    const [deviceList, setDeviceList] = useState([])

    async function getDevices () {
        try {
            let response = await fetch(GetUrlPrefix("device-controller") + `/devices`);
            const jsonData = await response.json();
            setDeviceList(jsonData)
        } catch (error) {
            console.log(error);
        }
    }

    const deleteDevice = (deviceId) => {
        const requestOptions = {
            method: 'DELETE',
        };
        fetch(GetUrlPrefix("device-controller") + `/devices/` + deviceId, requestOptions)
            .then(response => {
                if (response.ok) {
                    onDelete(deviceId)
                    return openSuccessNotification()
                } else {
                    return openErrorNotification()
                }
            })
            .catch(error => {
                console.log(error)
                return openErrorNotification()
            })
        return true
    }

    const openSuccessNotification = () => {
        notification.open({
            message: t('popups.device.deleteMsg.title'),
            description: t('popups.device.deleteMsg.message'),
            icon: (
                <CheckCircleOutlined style={{
                    color: 'green',
                }}
                />
            ),
        });
    };

    const openErrorNotification = () => {
        notification.open({
            message: t('popups.device.deleteMsgFail.title'),
            description: t('popups.device.deleteMsgFail.message'),
            icon: (
                <ExclamationCircleOutlined style={{
                    color: 'red',
                }}
                />
            ),
        });
    };

    const onDelete = (deviceId) => {
        let tmpDeviceList = [...deviceList]
        tmpDeviceList.every((device, index, list) => {
            if (device.id === deviceId) {
                tmpDeviceList.splice(index, 1)
                return false
            }
            return true
        })
        setDeviceList(tmpDeviceList)
    }

    const confirm = (deviceId, deviceName) => {
        Modal.confirm({
            title: t('devices.deleteForm.title'),
            content:
                <>
                    <p>{t('devices.deleteForm.message')}</p>
                    <p><b>{deviceName}</b></p>
                </>
            ,
            onOk: () => {
                    deleteDevice(deviceId)
                }
        });
    };

    const hideForm = () => {
        setFormIsVisible(false)
    }

    const showForm = () => {
        setFormIsVisible(true)
    }

    useEffect(() => {
        getDevices()
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <div className="button-menu">
                <h1>{t('devices.title')}</h1>
                <Button type="primary" onClick={() => {showForm()}}><PlusOutlined/>{t('buttons.add')}</Button>
            </div>
            <DeviceAddForm isVisible={formIsVisible} onCancel={hideForm} />
            <DeviceTable deviceList={deviceList} onSelectDelete={confirm} />
        </div>
    );
}

export default DevicePage;