import * as React from "react";

const SmallLight = ({ id, input }) => {

    var bgColor = "#DCDCDC"
    var shadow = ""

    switch (input) {
        case false:
            switch(id) {
                case 1:
                    bgColor = "#00FF00";
                    shadow = "0 0 6em #33ff33";
                    break;
                    default:
            }
            break;
        case true:
            switch(id) {
                case 2:
                    bgColor = "#FF0000";
                    shadow = "0 0 6em #ff3333";
                    break;
            }
            break;
        default:
    }
    
    return( 
        <div
            style={{
            appearance: "none",
            position: "relative",
            width: 30,
            height: 30,
            marginTop: 5,
            marginBottom: 5,
            marginLeft: 10,
            marginRight: 10,
            backgroundColor: bgColor,
            boxShadow: shadow,
            verticalAlign: "middle",
            borderRadius: "100%",
            display: "block", 
            }}
        >
            &nbsp;
        </div>
    );
}

export default SmallLight;
