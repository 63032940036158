import React, {useEffect} from "react";
import { Button, Form, Input, notification } from 'antd';
import { ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {GetUrlPrefix} from "../../config/HostConfig";

function DeviceAddForm({isVisible, onCancel}) {

    //Localisation
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const onFinish = (values) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: values.name,
                description: values.description,
                mqtt_name: values.mqtt_name,
                system_mqtt_name: values.system_mqtt_name,
            })
        };
        fetch(GetUrlPrefix("device-controller") + `/devices`, requestOptions)
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    return response.text().then(text => { throw new Error(text) })
                }
            })
            .then((data) => window.open("/devices/" + data.id, "_self"))
            .catch(error => {
                console.log(error)
                return openErrorNotification(error)
            })
    };

    const openErrorNotification = (error) => {
        notification.open({
            message: t('popups.device.createMsgFail.title'),
            description: "" + error,
            icon: (
                <ExclamationCircleOutlined style={{
                    color: 'red',
                }}
                />
            ),
        });
    };

    const toggleVisibility = () => {
        const element = document.getElementById("deviceAddForm");
        if (isVisible) {
            element.classList.remove("hidden");
        } else {
            element.classList.add("hidden");

        }
    }

    useEffect(() => {
        toggleVisibility()
        // eslint-disable-next-line
    }, [isVisible])

    return (
        <div id="deviceAddForm" style={{background: '#fff', borderRadius: '8px', padding: '5px', marginBottom: '10px'}}>
            <h2 style={{marginLeft: '10px'}}>{t('devices.addForm.title')}</h2>
            <Form
                autoComplete="off"
                labelCol={{ span: 4 }}
                name="basic"
                onFinish={onFinish}
                wrapperCol={{ span: 14 }}
            >
                <Form.Item
                    label= {t('devices.addForm.name')}
                    name="name"
                    rules={[{ required: true, message: t('devices.addForm.errMsgName') }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label= {t('devices.addForm.description')}
                    name="description"
                    rules={[{ required: true, message: t('devices.addForm.errMsgDesc') }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label= {t('devices.addForm.mqttName')}
                    name="mqtt_name"
                    rules={[{ required: true, message: t('devices.addForm.errMsgMqtt') }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label= {t('devices.addForm.systemMqttName')}
                    name="system_mqtt_name"
                    rules={[{ required: true, message: t('devices.addForm.errMsgSystemMqtt') }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary" htmlType="submit" style={{marginRight: '10px'}}>
                        <SaveOutlined />{t('buttons.save')}
                    </Button>
                    <Button type="default" onClick={() => {onCancel()}}>
                        {t('buttons.cancel')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default DeviceAddForm;