import React, {useEffect, useState} from "react";
import {Button, Form, Input, Modal, Space, Table} from 'antd';
import {DeleteOutlined, EditOutlined, SaveOutlined} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

function OpcuaConfig({device, onError, onSuccess}) {

    //Localisation
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const [connectors, setConnectors] = useState([
        {
            id: 1,
            key: 1,
            endpoint: "localhost",
        },
        {
            id: 2,
            key: 2,
            endpoint: "192.168.2.1",
        }
    ])
    const [form] = Form.useForm();


    const onSelectDelete = (connectorId, connectorEndpoint) => {
        Modal.confirm({
            title: t('devices.devicePage.connectors.deleteForm.title'),
            content:
                <>
                    <p>{t('devices.devicePage.connectors.deleteForm.message1')}</p>
                    <p><b>{connectorEndpoint}</b></p>
                    <p>{t('devices.devicePage.connectors.deleteForm.message2')}</p>
                </>
            ,
            onOk: () => {
                deleteConnector(connectorId)
            }
        });
    }

    const deleteConnector = (connectorId) => {
        // Send to backend
        // TODO

        // Delete from list
        let tmpConnectors = []
        connectors.forEach(connector => {
            if(connectorId !== connector.id) {
                tmpConnectors.push({
                    id: connector.id,
                    key: connector.key,
                    endpoint: connector.endpoint,
                })
            }
        });
        setConnectors(tmpConnectors)
        // Show pop-up
        onSuccess(t('popups.connector.deleteMsg.title'), t('popups.connector.deleteMsg.message'))
    }

    const onSelectUpdate = (connectorId, endpoint) => {
        Modal.confirm({
            title: t('devices.devicePage.connectors.updateForm.title'),
            content:
                <>
                    <Input id="opcuaUpdate" defaultValue={endpoint} />
                </>
            ,
            onOk: () => {
                updateConnector(connectorId)
            }
        });
    }

    const updateConnector = (connectorId) => {
        let endpoint = document.getElementById("opcuaUpdate").value

        // Send to backend
        // TODO

        // Update list
        let tmpConnectors = [...connectors]
        tmpConnectors.forEach(connector => {
            if(connectorId === connector.id) {
                connector.endpoint = endpoint
            }
        });
        setConnectors(tmpConnectors)

        // Show pop-up
        onSuccess(t('popups.connector.updateMsg.title'), t('popups.connector.updateMsg.message'))
    }

    const addConnector = (values) => {
        // Send to backend
        // TODO

        // Add to list
        let tmpConnectors = [...connectors]
        tmpConnectors.push({
            id: connectors.length + 1, // TODO
            key: connectors.length + 1, // TODO
            endpoint: values.endpoint,
        })
        setConnectors(tmpConnectors)

        // Clear form
        form.resetFields()

        // Show pop-up
        onSuccess(t('popups.connector.createMsg.title'), t('popups.connector.createMsg.message'))
    }

    const columns = [
        {
            title: t('devices.devicePage.connectors.opcua.table.endpoint'),
            dataIndex: 'endpoint',
            key: 'endpoint',
            render: (text) => (<>{text}</>)
        },
        {
            title: t('devices.devicePage.connectors.opcua.table.action'),
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        icon={<EditOutlined />} onClick={() => {onSelectUpdate(record.id, record.endpoint)}}>
                        {t('buttons.update')}
                    </Button>
                    <Button
                        icon={<DeleteOutlined />} onClick={() => {onSelectDelete(record.id, record.endpoint)}}>
                        {t('buttons.delete')}
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <div style={{borderRadius: '8px', padding: '5px', marginBottom: '10px'}}>
            <Table
                columns={columns}
                dataSource={connectors}
                pagination={{
                    hideOnSinglePage: true,
                }}

            />
            <h3>{t('devices.devicePage.connectors.opcua.addForm.title')}</h3>
            <Form
                autoComplete="off"
                initialValues={{
                    deviceId: device.id,
                    endpoint: "",
                }}
                form={form}
                labelCol={{ span: 4 }}
                name="basic"
                onFinish={addConnector}
                wrapperCol={{ span: 14 }}
            >
                <Form.Item
                    hidden={true}
                    label="Device Id"
                    name="deviceId"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label= {t('devices.devicePage.connectors.opcua.addForm.endpoint')}
                    name="endpoint"
                    rules={[{ required: true, message: t('devices.devicePage.connectors.opcua.addForm.errorMsg') }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        <SaveOutlined /> Save
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default OpcuaConfig;