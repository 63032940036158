import React, {useEffect, useState} from "react";
import { Button, Tabs } from 'antd';
import {PlusOutlined} from "@ant-design/icons";
import DeviceEditForm from "../../components/devices/DeviceEditForm";
import DeviceMeasurementTable from "../../components/devices/DeviceMeasurementTable";
import { useLoaderData } from "react-router-dom";
import DeviceConnectorsOverview from "../../components/devices/DeviceConnectorsOverview";
import DeviceAttributeOverview from "../../components/devices/attributes/DeviceAttributeOverview";
import { useTranslation } from 'react-i18next';
import {GetUrlPrefix} from "../../config/HostConfig";

export async function loader({ params }) {
    return params.deviceId;
}

function DeviceDetails() {

    //Localisation
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const [deviceId, setDeviceId] = useState(-1)
    const [device, setDevice] = useState({name: ''})

    const paramDeviceId = useLoaderData();

    async function getDevice () {
        try {
            let response = await fetch(GetUrlPrefix("device-controller") + `/devices/` + deviceId);
            const jsonData = await response.json();
            setDevice(mapDevice(jsonData))
        } catch (error) {
            console.log(error);
        }
    }

    const mapDevice = (rawData) => {
        return {
            id: rawData.id,
            name: rawData.name,
            description: rawData.description,
            mqtt_name: rawData.mqtt_name,
            system_mqtt_name: rawData.system_mqtt_name,
        }
    }

    const onDeviceUpdate = (deviceDetails) => {
        setDevice({
            ...device,
            name: deviceDetails.name,
            description: deviceDetails.description,
            mqtt_name: deviceDetails.mqtt_name,
            system_mqtt_name: deviceDetails.system_mqtt_name,
        });
    }

    useEffect(() => {
        setDeviceId(paramDeviceId)
        getDevice()
        // eslint-disable-next-line
    }, [deviceId])

    return (
        <div>
            <h1>{device.name}</h1>
            <Tabs
                type="card"
                items={[
                    {
                        label: t('devices.devicePage.details.title'),
                        key: '1',
                        children: <DeviceEditForm deviceVal={device} onUpdate={onDeviceUpdate} />,
                    },
                    {
                        label: t('devices.devicePage.attributes.title'),
                        key: '2',
                        children:
                            <DeviceAttributeOverview device={device} />
                    },
                    {
                        label: t('devices.devicePage.connectors.title'),
                        key: '3',
                        children:
                            <div>
                                <DeviceConnectorsOverview device={device} />
                            </div>
                    },
                    {
                        label: t('devices.devicePage.measurements.title'),
                        key: '4',
                        children:
                            <div>
                                <div className="button-menu" style={{justifyContent: 'right'}}>
                                    <Button type="primary"><PlusOutlined />{t('buttons.add')}</Button>
                                </div>
                                <DeviceMeasurementTable />
                            </div>
                    },
                ]}
            />
        </div>
    );
}

export default DeviceDetails;