import React from "react";
import { useState, useEffect } from 'react';
import { Card, Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import {GetUrlPrefix} from "../../config/HostConfig";
const { Meta } = Card;


function AppCard({name, port, url, logo, available}) {

    //Localisation
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    //Set initial state
    const [reachable, setReachable]=useState(getStatusFromApp)


    async function getStatusFromApp () {
        try {
          let response = await fetch (GetUrlPrefix("") + ':' + port + '/api/ping');
          let pingAnswer = await response.json();
          if (pingAnswer.message === "Pong") {
            setReachable(1);
          }
        } catch (error) {
          console.log(error);
        }
      }
    
    function getCardStatus (status) {
        let statusDisplay;
        switch (status){
            case 0:
                statusDisplay="error"
                break;
            case 1:
                statusDisplay="success" 
                break;
            case 2:
                statusDisplay="warning"
                break;
            default:
                statusDisplay="error"

        } return statusDisplay;
    }

    function getRibbonColor (status) {
        let ribbonColor;
        switch (status){
            case 1:
                ribbonColor="green" 
                break;
           
            default:
                ribbonColor="gray"

        } return ribbonColor;
    }

    function getCardText (status) {
        let textDisplay;
        switch (status){
            case 0:
                textDisplay= t('applications.status.error')
                break;
            case 1:
                textDisplay= t('applications.status.active') 
                break;
            case 2:
                textDisplay=t('applications.status.noData')
                break;
            default:
                textDisplay= t('applications.status.error')

        } return textDisplay;

    }

    const openApp = () => {
        if (available) {
            window.open("/applications/" + url, "_self");
        }
    }

    return (
        <div onClick={() => openApp()}>
            {available ? (
                <Badge.Ribbon color={getRibbonColor(reachable)} text={<>{getCardText(reachable)}</>}>
                    <Card
                        hoverable={available}
                        //extra={<a href="#">More</a>}
                        style={{
                            width: 240,
                            padding: 5,
                            margin: 5,
                        }}
                        cover=
                        {available
                            ? <img alt={name} src={"/img/" + logo} />
                            : <img alt={name} style={{ opacity: 0.4}} src={"/img/" + logo} />
                        }
                    >
                        {available
                            ? <Meta title={name}/>
                            : <Meta title={name} description={t('applications.status.notActivated')}/>
                        }
                        {/* {available && (
                            <Badge status = {getCardStatus(reachable)} text = {getCardText(reachable)} />
                        )}    */}
                    </Card>
                </Badge.Ribbon>
            ) : (
                <Card
                    hoverable={available}
                    //extra={<a href="#">More</a>}
                    style={{
                        width: 240,
                        padding: 5,
                        margin: 5,
                    }}
                    cover=
                    {available
                        ? <img alt={name} src={"/img/" + logo} />
                        : <img alt={name} style={{ opacity: 0.4}} src={"/img/" + logo} />
                    }
                >
                    <Meta title={name}/>
                   {/*  {available && (
                        <Badge status = {getCardStatus(reachable)} text = {getCardText(reachable)} />
                    )}    */}
                </Card>
            )}
        </div>
    );
}



export default AppCard;