import './App.css';
import React from 'react';
import { Layout } from 'antd';
import { AppstoreOutlined, ClusterOutlined, SettingOutlined, CloudServerOutlined  } from '@ant-design/icons';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';

const { Header, Content, Sider, Footer } = Layout;

function App({content, menu, selectedApp, logo}) {

  //Localisation
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
  };

  function getItem(label, key, icon, disabled, children, type) {
    return {
      key,
      icon,
      disabled,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem(t('menu.applications'), 'applications', <AppstoreOutlined />),
    getItem(t('menu.devices'), 'devices', <ClusterOutlined />),
    getItem(t('menu.cloudLinks'), 'cloudLinks', <CloudServerOutlined />, true),
    getItem(t('menu.settings'), 'settings', <SettingOutlined />),
  ];

  const onClick = (item) => {
    window.open("/" + item.key, "_self");
  }

  const onSelect = (item) => {
    window.open("/" + item.key, "_self");
  };

  return (
      <div
          className='app'
          style={{ height: '100vh' }}
      >
        <Layout
            style={{ height: '100%' }}>
          <Header className="header" 
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%"
            }}
          >
            <div className="header-content">
              <a href="/applications" className="header-image-home">
                <img className="header-image" src={"/abp_logo.png"}  alt={"ABP Logo"}/>
              </a>
              {selectedApp && <img className="header-image-app" src={"/img/" + logo}  alt={selectedApp}></img>}
              <h1 style={{color:'#f5835d'}}>{!selectedApp && <span>{t("title")}</span>}{selectedApp && <span> {selectedApp}</span>}</h1>
            </div>
          </Header>
          <Layout>
            <Sider
                width={200}
                className="fixed-sider"
                style={{ backgroundColor: '#fff' }}
            >
              <Menu
                  mode="inline"
                  onClick={onClick}
                  onSelect={onSelect}
                  items={items}
                  defaultSelectedKeys={menu}
              />
            </Sider>
            <Layout style={{ padding: '0 24px 24px' }}>
              <Content
                  className="site-layout-background"
                  style={{
                    padding: 24,
                    marginLeft: 200,
                    marginTop: 64,
                    marginBottom:64,
                    minHeight: 280,
                  }}
              >
                {content}
              </Content>
            </Layout>
          </Layout>
          <Footer>&copy; {new Date().getFullYear()} ABP Induction</Footer>
        </Layout>
      </div>
  );
}

export default App;

